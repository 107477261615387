import React, {Component} from "react";
import PropTypes from 'prop-types';
import {BeyonityUiUtils, Button} from '@beyonityeu/beyonity-ui-buttons';
import Hammer from './../../libraries/hammer.js/hammer.min.js';

import "./index.css";




class ScrollHorizontal extends Component {

    lastPosX = 0;// onDrag - set on start

    constructor(props) {
        super(props);
        this.dragArea = React.createRef();
        this.dragBlock = React.createRef();
        this.state = {
            isDragging: false,
            maxPosX   : 0,
            lastPosX  : 0// onScroll - set on end
        };
    }


    componentDidMount() {
        const hm = new Hammer(this.dragArea.current);
        hm.get('pan').set({direction: Hammer.DIRECTION_HORIZONTAL});
        hm.on('pan', this.handleDrag);
        hm.on('press', this.handlePress);
        hm.on('pressup', this.handlePressUp);

        const resizeObserver = new ResizeObserver(e => {
                this.setDragArea(e);
                this.setMaxPosX();
            }),
            mutationObserver = new MutationObserver(this.setMaxPosX);
        resizeObserver.observe(this.dragArea.current);
        mutationObserver.observe(this.dragBlock.current, {childList: true, subtree: true, characterData: true});
    }


    componentDidUnMount() {
        resizeObserver.unobserve(this.dragArea.current);
        mutationObserver.disconnect();
    }


    getOffset = el => {
        const rect = el.getBoundingClientRect();
        return {
            x1: rect.left + window.scrollX,
            y1: rect.top + window.scrollY,
            x2: rect.left + window.scrollX + el.clientWidth,
            y2: rect.top + window.scrollY + this.dragBlock.current.clientHeight
        };
    };

    setDragArea = e => {
        this.setState({
            dragArea: this.getOffset(e[0].target)
        });
    };

    setMaxPosX = () => {
        this.setState({
            maxPosX: this.dragArea.current.scrollWidth - this.dragArea.current.clientWidth
        });
    };

    getLastPosX = () => this.dragArea.current.scrollLeft;

    startDrag = () => {
        this.setState({isDragging: true});
        this.lastPosX = this.getLastPosX();
    };

    endDrag = () => this.setState({
        isDragging: false
    });

    handleDrag = e => {
        const {isDragging, maxPosX, dragArea} = this.state;
        if (!isDragging) {
            if (e.center.x < dragArea.x1 || e.center.x > dragArea.x2 || e.center.y < dragArea.y1 || e.center.y > dragArea.y2) {
                return false;
            }
            this.startDrag(e);
        }
        let posX = -1 * e.deltaX + this.lastPosX;
        if (posX < 0) {
            posX = 0;
        } else if (posX > maxPosX) {
            posX = maxPosX;
        }
        this.dragArea.current.scrollTo({
            top     : 0,
            left    : posX,
            behavior: "instant"
        });
        if (e.isFinal) {
            this.endDrag();
        }
    };

    handlePress = e => this.endDrag();

    handlePressUp = e => this.endDrag();

    handleSrcoll = e => {
        this.setState({
            lastPosX: this.getLastPosX()
        });
    };

    scroll = steps => {
        const {maxPosX, lastPosX} = this.state,
            left = steps < 0 ? Math.max(0, lastPosX + steps) : Math.min(maxPosX, lastPosX + steps);
        this.dragArea.current.scrollTo({
            top     : 0,
            left    : left,
            behavior: "smooth"
        });
    };


    render() {
        const {id, controlls, className, children} = this.props,
            {isDragging, maxPosX, lastPosX} = this.state,
            isScrollable = maxPosX > 0,
            isScrolled = lastPosX > 0,
            isScrolledEnd = lastPosX < maxPosX;


        return (
            <div
                className={BeyonityUiUtils.generateClassNames([
                    'bey-ui-scrollHorizontal-Wrapper',
                    isScrollable && 'scrollable',
                    !isScrolled && 'unScrolled',
                    !isScrolledEnd && 'unScrolledEnd',
                    className
                ])}
            >
                <div
                    className={BeyonityUiUtils.generateClassNames([
                        'bey-ui-scrollHorizontal_control',
                        'bey-ui-scrollHorizontal_prev',
                        controlls.className
                    ])}
                >
                    <Button
                        iconLeft="chevron_left"
                        buttonType="secondary"
                        buttonVariant="default"
                        size="s"
                        onClick={() => this.scroll(-1 * controlls.scrollSteps)}
                    />
                </div>
                <div
                    className={BeyonityUiUtils.generateClassNames([
                        'bey-ui-scrollHorizontal_control',
                        'bey-ui-scrollHorizontal_next',
                        controlls.className
                    ])}
                >
                    <Button
                        iconLeft="chevron_right"
                        buttonType="secondary"
                        buttonVariant="default"
                        size="s"
                        onClick={() => this.scroll(controlls.scrollSteps)}
                    />
                </div>
                <div
                    id={id}
                    className={BeyonityUiUtils.generateClassNames([
                        'bey-ui-scrollHorizontal',
                        isDragging && 'isDragging'
                    ])}
                    onScroll={this.handleSrcoll}
                    onWheel={e => this.scroll(e.deltaY)}
                    ref={this.dragArea}
                >
                    <div
                        className="bey-ui-scrollHorizontal-dragBlock"
                        ref={this.dragBlock}
                    >
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}



export default ScrollHorizontal;


ScrollHorizontal.propTypes = {
    controlls: PropTypes.object
};


ScrollHorizontal.defaultProps = {
    controlls: {
        scrollSteps: 150,
        className  : ''
    }
};

