import {Component} from "react";
import {BeyonityUiUtils} from "@beyonityeu/beyonity-ui-buttons";
import PropTypes from "prop-types";
import "./LoadingScreen.css";
import {AppIcons} from "./AppIcon";




class LoadingScreen extends Component{
    constructor(props) {
        super(props);
        this.state = {
        }
    }



    render() {
        const {
            text,
            msg,
            finished,
            app,
            customer
        } = this.props;


        const classes = BeyonityUiUtils.generateClassNames([
                `beyonity-ui--app-loading-screen`,
                text && `beyonity-ui--app-loading-screen--with-text`,
                finished && `beyonity-ui--app-loading-screen--finished`,
            customer && `beyonity-ui--app-loading-screen--customer`
        ]);

        if (customer && customer.ci) {

            // override the css variable --color-gradient of the  beyonity-ui--app-loading-screen with the customer
            // primary color specvifically grab the beyonity-ui--app-loading-screen
            document.querySelector('.beyonity-ui--app-loading-screen').style.setProperty('--color-gradient', `${( customer.ci.primary )}`);
        }

        return (
            <div className={classes}>
                <div className={"beyonity-ui--app-loading-screen__gradient"}/>
                <div className={'beyonity-ui--app-loading-screen__body'}>
                    <div className={`beyonity-ui--app-loading-screen__content-wrapper`}>
                        <div className={`beyonity-ui--app-loading-screen__customer-logo`}>
                            {customer && customer.logo && <img src={customer.logo} alt={customer.name}/>}
                        </div>
                        <div>
                            <div className={`beyonity-ui--app-loading-screen__content`}>
                                <div className={`beyonity-ui--app-loading-screen__text beyonity-ui--text__heading-1`}>
                                    {text}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"beyonity-ui--app-loading-screen__footer"}>
                        <div className={"beyonity-ui--app-icon beyonity-ui--animated"}>
                            {this._getIcon(app)}
                        </div>
                        <div className={"beyonity-ui--branding"}>
                                <span
                                    className={"beyonity-ui--branding__by beyonity-ui--text__label-light color-text--gray-600"}>Powered by</span>
                            <span
                                className={"beyonity-ui--branding__bey beyonity-ui--text__small-bold beyonity-ui--text__branding"}>BEYONITY</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    _getIcon(app) {
        switch (app) {
            case "portfolio":
                return AppIcons.portfolio;
        }
    }
}

LoadingScreen.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    msg : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    finished: PropTypes.bool,
    app : PropTypes.string
};

export default LoadingScreen;