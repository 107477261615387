import React, {Component} from 'react';
import PropTypes from "prop-types";

import {BeyonityUiUtils} from '@beyonityeu/beyonity-ui-buttons';

import './index.css';




class Box extends Component {

    transitionDuration = 350;
    rto;


    constructor(props) {
        super(props);
        this.state = {
            lastDefinedPosition: 0,
            panPosition        : -window.innerHeight,
            transition         : false,
            isLandscape: window.innerHeight < window.innerWidth
        };
        this.boxRef = React.createRef();

        this.animate = this.animate.bind(this);
        this.recalculate = this.recalculate.bind(this);
    }


    componentDidMount() {
        this.setState({
            lastDefinedPosition: 0, panPosition: this.state.isLandscape ?
                -this.boxRef.current.clientWidth : -this.boxRef.current.clientHeight
        });

        window.addEventListener('resize', this.recalculate);
        // onload event
        window.addEventListener('load', this.recalculate);
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.recalculate);
        window.removeEventListener('load', this.recalculate);
    }


    onTouchPan(e) {
        this.onDrag(e.touches[0].clientY)
    }


    onMousePan(e) {
        this.onDrag(e.pointers[0].clientY)
    }


    onTouchDrop(e) {
        if (this.state.isDropping) return;
        this.setState({isDropping: true},
            () => {
                this.onDrop(e.changedTouches[0].clientY)
            });
    }


    onMouseDrop(e) {
        if (this.state.isDropping) return;
        this.setState({isDropping: true},
            () => {
                this.onDrop(e.changedPointers[0].clientY)
            });
    }


    onMouseDown(e) {
        if (!this.startY) this.startY = -this.boxRef.current.clientHeight + ( window.innerHeight - e.clientY );
    }


    onTouchStart(e) {
        if (!this.startY) this.startY = -this.boxRef.current.clientHeight + ( window.innerHeight - e.touches[0].clientY );
    }


    onDrag(coordinate) {
        if (!this.startY) this.startY = -this.boxRef.current.clientHeight + ( window.innerHeight - coordinate );
        if (this.state.isLandscape) return;
        let newPosition = -this.boxRef.current.clientHeight + ( window.innerHeight - coordinate );
        if (newPosition >= 0) {
            newPosition = 0;
        }
        if (newPosition <= -this.boxRef.current.clientHeight) {
            newPosition = -this.boxRef.current.clientHeight;
        }
        this.setState({panPosition: newPosition, transition: false});
    }


    onDrop(coord) {
        /// e is a touchend event, get the total distance moved+
        let distance = Math.abs(( -this.boxRef.current.clientHeight + ( window.innerHeight - coord ) ) - this.startY)
        if (distance < 20) {
            this.startY = false;
            this.setState({isDropping: false});
            this.nextState();
            return;
        }
        this.startY = false;
        if (this.state.isLandscape) return;
        if (this.state.lastDefinedPosition === 2) {
            if (this.state.panPosition > -this.boxRef.current.clientHeight / 2) {
                this.animate(0, 2)
            } else {
                this.close();
            }
        } else if (this.state.lastDefinedPosition === 1) {
            // smaller than 20 percent then the window height
            if (this.state.panPosition > -this.boxRef.current.clientHeight + 75) {
                this.animate(0, 2)
            } else {
                this.animate(-this.boxRef.current.clientHeight + 75, 1)
            }
        }
        this.setState({isDropping: false});
    }

    recalculate = () => {
        if (!this.boxRef) return;
        if (!this.boxRef.current) return;
        if (!this.boxRef.current.clientHeight) return;
        let clientHeight = this.boxRef.current.clientHeight;
        this.setState({isLandscape: window.innerWidth > window.innerHeight});
        clearTimeout(this.resizeTimeout)
        this.resizeTimeout = setTimeout(() => {
                if (this.state.lastDefinedPosition === 1) {
                    let panPosition = -clientHeight + 75;
                    if (this.state.isLandscape) {
                        panPosition = 0;
                    }
                    this.setState({panPosition: panPosition});
                } else if (this.state.lastDefinedPosition === 0) {
                    this.setState({panPosition: -clientHeight});
                } else if (this.state.lastDefinedPosition === 3) {
                    this.setState({panPosition: 0})
                }
            }, 50
        )
    }


    preview() {
        let panPosition = -this.boxRef.current.clientHeight + 75;
        if (this.state.isLandscape) {
            panPosition = 0;
        }


        if (this.isOpen()) {
            this.close(
                () => {
                    this.animate(panPosition, 1);
                }
            );
        } else {
            this.onHidden()
            this.animate(panPosition, 1)
        }
    };


    animate(targetPosition, definedPosition, elasticity = 0.4, callback = null) {
        if (!this.animationFrame) {
            this.startPosition = this.state.panPosition;
            this.actualTargetPosition = targetPosition;
            this.intermediateTargetPosition = targetPosition;
            this.difference = this.intermediateTargetPosition - this.startPosition;
            this.start = null;
            this.bounceIntensity = this.getBounceIntensity(this.difference);
            this.timingFunction = this.spring;
            this.animationDuration = this.state.isLandscape ? 350 : 400
            this.progressMultiplier = this.state.isLandscape ? 1 : 0.7
        }

        const step = timestamp => {
            if (!this.start) this.start = timestamp;
            this.progress = ( timestamp - this.start ) / ( this.animationDuration * this.progressMultiplier );
            let newPosition = this.startPosition + ( this.difference * this.timingFunction(this.animationDuration, 0, this.progress, this.animationDuration) );
            newPosition = Math.ceil(newPosition);

            if (( this.difference >= 0 && newPosition >= this.intermediateTargetPosition ) || ( this.difference < 0 && newPosition <= this.intermediateTargetPosition )) {
                newPosition = this.intermediateTargetPosition;
                this.startPosition = newPosition;
                this.start = null;
                if (this.state.isLandscape) this.bounceIntensity = false;
                if (this.bounceIntensity > 1) {
                    if (!this.bounce) {
                        this.bounce = true;
                        this.timingFunction = this.bounceUpEaseOut;
                        this.progressMultiplier *= 0.7;
                        this.progress = ( timestamp - this.start ) / ( this.animationDuration * 0.1 );
                        this.intermediateTargetPosition = this.actualTargetPosition - this.bounceIntensity;
                        this.difference = this.intermediateTargetPosition - this.startPosition;
                        this.bounceIntensity *= elasticity;
                    } else {
                        this.bounce = false;
                        this.timingFunction = this.bounceDownEaseIn;
                        this.intermediateTargetPosition = this.actualTargetPosition;
                        this.difference = this.intermediateTargetPosition - this.startPosition;
                    }
                } else if (this.bounceIntensity <= 1) {
                    this.intermediateTargetPosition = this.actualTargetPosition;
                    this.difference = this.intermediateTargetPosition - this.startPosition;
                    this.bounceIntensity = false;
                }
                if (!this.bounceIntensity) {
                    this.setState({
                        panPosition        : this.actualTargetPosition,
                        lastDefinedPosition: definedPosition
                    }, () => {
                        cancelAnimationFrame(this.animationFrame);
                        this.animationFrame = null;
                        callback && callback();
                    })
                    return;
                }
            }
            this.state.isLandscape ? this.boxRef.current.style.left = newPosition + 'px' : this.boxRef.current.style.bottom = newPosition + 'px';
            this.animationFrame = requestAnimationFrame(step);
        };

        if (this.animationFrame) {
            /* setTimeout(() => {
                cancelAnimationFrame(this.animationFrame);
                this.animationFrame = null;
             this.animate(targetPosition, definedPosition, elasticity, callback)
            }, 600);
             */
        } else {
            this.animationFrame = requestAnimationFrame(step);
        }
    }


    getBounceIntensity(difference) {
        difference = Math.abs(difference);
        let intensity = ( difference ) / ( window.innerHeight / 2 ) * 25;
        // Ensure the intensity is within the range of 10 to 30
        intensity = Math.max(intensity, 10);
        intensity = Math.min(intensity, 30);
        return intensity;
    }


    /**
     * A timing function that starts slo and then speeds u like a spring
     * @param t time
     * @param b start position
     * @param c end position
     * @param d duration
     * @returns {number}
     */
    spring(t, b, c, d) {
        return c * ( t /= d ) * t * t + b;
    }


    // Timing function for bounce up
    bounceUpEaseOut = (t, b, c, d) => {
        t /= d;
        return -c * t * ( t - 2 ) + b;
    }

    // Timing function for bounce down
    bounceDownEaseIn = (t, b, c, d) => {
        t /= d;
        return c * t * t + b;
    }

    open() {
        if (this.boxRef.current === null) return;
        if (( this.state.lastDefinedPosition === 1 || this.state.lastDefinedPosition === 0 ) && this.state.isLandscape) {
            this.animate(0, 2)
        }
        if (this.state.lastDefinedPosition === 1 && !this.state.isLandscape) {
            this.animate(0, 2)
        }
        if (this.state.lastDefinedPosition === 0 && !this.state.isLandscape) {
            this.animate(0, 2)
        }
    }


    close(callback) {
        if (this.boxRef.current === null) return;
        if (this.state.isLandscape) {
            this.animate(-this.boxRef.current.clientWidth, 0, 0, () => {
                callback && callback()
                this.onHidden();
                this.onClose && this.onClose();
            })
        } else {
            this.animate(-this.boxRef.current.clientHeight, 0, 0, () => {
                callback && callback()
                this.onHidden();
                this.onClose && this.onClose();
            })
        }

    };


    onHidden() {

    }


    onClosed() {
        // to be overridden in subclasses
    }



    isOpen() {
        return this.state.lastDefinedPosition > 0;
    }


    nextState() {
        if (this.state.lastDefinedPosition === 0 && !this.state.isLandscape) {
            this.preview()
        } else if (this.state.lastDefinedPosition === 0 && this.state.isLandscape) {
            this.open();
        } else if (this.state.lastDefinedPosition === 1 && !this.state.isLandscape) {
            this.open()
        } else if (this.state.lastDefinedPosition === 1 && this.state.isLandscape) {
            this.close()
        } else if (this.state.lastDefinedPosition === 2) {
            this.close()
        }
    }


    render = () => {

        const {
                lastDefinedPosition,
                panPosition,
                isLandscape,
            } = this.state,
            {
                innerRef,
                className,
                secondary,
                zIndex,
            } = this.props;


        const classes = BeyonityUiUtils.generateClassNames([
            `beyonity-ui--side-box`,
            lastDefinedPosition === 1 && `beyonity-ui--preview`,
            lastDefinedPosition === 2 && `beyonity-ui--open`,
            secondary && `beyonity-ui--side-box__secondary`,
            isLandscape && `beyonity-ui--side-box__landscape`,
            className && className
        ]);


        let position = {
            bottom: panPosition + "px",
        }


        if (this.state.isLandscape) {
            position = {
                left: panPosition + "px",
            }
        }


        return (
            <div ref={innerRef}
                 style={{zIndex}}
                 className={classes}>
                <div className={"beyonity-ui--side-box__content"}
                     id={"beyonity-ui-id--side-box"}
                     style={{...position}}
                     ref={this.boxRef}
                >
                    {this.renderContent()}
                </div>
            </div>
        );
    }


    renderContent() {
    }

}

export default Box;


Box.defaultProps = {
    onClose: () => {
    },
    zIndex             : 100,
    closeOnOutsideClick: false,
}

Box.propTypes = {
    /**
     * A function that is called when the box is closed
     */
    onClose: PropTypes.func,
    /**
     * A `secondary` boy will be opened at the side of the Sidebar
     */
    secondary: PropTypes.bool,
    /**
     * z-Index of the box
     */
    zIndex: PropTypes.number,
    /**
     * Enabling this will make the box close when the user clicks outside the box
     */
    closeOnOutsideClick: PropTypes.bool,

};
