import React from 'react';
import Box from "../Box";
import ContentBoxFooter from "./ContentBoxFooter";
import ContentBoxBody from "./ContentBoxBody";
import {Button} from "@beyonityeu/beyonity-ui-buttons";
import "./ContentBox.css"
import Hammer from './../../libraries/hammer.js/hammer.min.js';




class ContentBox extends Box {

    draggableRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            openState: 0,
            ...this.state
        };
    }


    componentDidMount() {
        super.componentDidMount()
        const node = this.draggableRef.current,
            hm = new Hammer(node);
        hm.on("pan", this.onMousePan.bind(this));
        hm.on("panend", this.onMouseDrop.bind(this));
        node.addEventListener('touchmove', this.onTouchPan.bind(this));
        node.addEventListener('touchend', this.onTouchDrop.bind(this));
        node.addEventListener('mousedown', this.onMouseDown.bind(this));
        node.addEventListener('touchstart', this.onTouchStart.bind(this));
    }


    componentWillUnmount() {
        super.componentWillUnmount()
        const node = this.draggableRef.current;
        node.removeEventListener('touchmove', this.onTouchPan.bind(this));
        node.removeEventListener('touchend', this.onTouchDrop.bind(this));
        node.removeEventListener('mousedown', this.onMouseDown.bind(this));
        node.removeEventListener('touchstart', this.onTouchStart.bind(this));
    }


    onMousePan(e) {
        super.onMousePan(e);
    }


    onTouchPan(e) {
        super.onTouchPan(e);
    }


    onMouseDown(e) {
        super.onMouseDown(e);
    }


    onTouchStart(e) {
        super.onTouchStart(e);
    }


    onTouchDrop(e) {
        super.onTouchDrop(e);
    }


    onMouseDrop(e) {
        super.onMouseDrop(e);
    }


    render() {
        super.render();
    }


    renderContent() {
        super.renderContent();
        return (
            <div className={"beyonity-ui--content-box"}>
                <div className={"beyonity-ui--content-box__header"} ref={this.draggableRef}>
                    <div className={"beyonity-ui--content-box__header-top"}>
                        <div className={"beyonity-ui--content-box__drag-indicator"}/>
                    </div>
                    <div className={"beyonity-ui--content-box__header-bottom"}
                         onTouchStart={e => e.stopPropagation()}
                         onTouchMove={e => e.stopPropagation()}
                         onMouseDown={e => e.stopPropagation()}
                         onTouchEnd={e => e.stopPropagation()}
                         onMouseMove={e => e.stopPropagation()}>
                        <Button
                            onClick={this.nextState.bind(this)}
                            className={`beyonity-ui--content-box__header-btn`}
                            iconLeft="arrow_back"
                            buttonVariant="minimal"
                            buttonType="secondary"
                        />
                        <div
                            className={"beyonity-ui--content-box__header-title beyonity-ui--text__large-highlighted"}>{this.renderHeader()}</div>
                        <div className={"beyonity-ui--content-box__header-right-placeholder"}/>
                        <div className={"beyonity-ui--content-box__header-shadow"}/>
                    </div>
                </div>
                <ContentBoxBody>
                    {this.renderBody()}
                </ContentBoxBody>
                {this.renderFooter() !== null &&
                    <ContentBoxFooter>
                    {this.renderFooter()}
                    </ContentBoxFooter>
                }
            </div>
        );


    }




    open() {
        super.open();
    }


    onHidden() {
        super.onHidden();
    }

    preview() {
        super.preview();
    }


    close(callback) {
        super.close(callback);
    }


    isOpen() {
        return super.isOpen();
    }


    nextState = () => {
        super.nextState();
    }

    renderHeader() {
        // To be overridden by child class
    }


    renderBody() {
        // To be overridden by child class
    }


    renderFooter() {
        // To be overridden by child class
    }

}

export default ContentBox;
