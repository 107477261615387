import React, {Component} from 'react';
import styles from './index.module.scss';




class SidebarHeader extends Component {

    constructor(props) {
        super(props);
    }


    render() {

        const {children} = this.props;

        return (
            <div className={styles.header}>
                {children}
            </div>
        );
    }

}

export default SidebarHeader;
