import {FormatData, request} from "../../utils";
import {BeyonityUiUtils} from "@beyonityeu/beyonity-ui-buttons";
import {CATEGORY_COMMERCE, CATEGORY_LIVING} from "../FilterBox";


// const map with categories and icons
export const CATEGORY_ICONS = {
    "living"  : "home",
    "commerce": "corporate_fare",
    "parking" : "local_parking",
    "misc"    : "miscellaneous_services",
}



export const APP_GLOBAL = {
    components: {},
    language  : {},
    data      : {}
};// APP_GLOBAL (global object in react)


export class DataManager {

    constructor(dataApi, onLanguageLoaded, onCustomerLoaded, onDataLoaded, onCustomContentLoaded, onProcessed, onError) {
        this.data = {};
        this.domain = dataApi.domain;
        this.error = false;
        this.onError = onError;
        this.dataApi = dataApi;
        this.customContentLoaded = false
        this.onDataLoaded = onDataLoaded;
        this.onCustomerLoaded = onCustomerLoaded;
        this.onLanguageLoaded = onLanguageLoaded;
        this.onProcessed = onProcessed;
        this.onCustomContentLoaded = onCustomContentLoaded;
    }


    loadFromServer() {
        const {
            api,
            id,
            language
        } = this.dataApi;

        // check if the data is already in the session storage and if it is not older than 1h
        // if so load it from there
        let data = sessionStorage.getItem(`data_${id}_${language}`);
        if (data) {
            data = JSON.parse(data);
            // if data not older than half an hour
            if (Date.now() - data.from < 60 * 36000) {

                // setting up global data
                APP_GLOBAL.data = data.data;
                APP_GLOBAL.language = data.language;
                this.onLanguageLoaded();

                APP_GLOBAL.components = data.components
                APP_GLOBAL.selectedLanguage = data.selectedLanguage;
                APP_GLOBAL.customer = data.customer;
                this.data = APP_GLOBAL.data;
                this.language = APP_GLOBAL.language;

                // setting up language for formatting
                FormatData.init(
                    undefined,
                    undefined,
                    undefined,
                    data.language.general.measurementUnits.rooms,
                    data.language.general.measurementUnits.units);

                // if this is the case it is also possible that filters have been stored in the session storage,
                // so we need to check for that as well
                APP_GLOBAL.defaultFilterSettings = this._getInitialFilterSettings();
                let filterSettings = sessionStorage.getItem("filterSettings" + this.data.dsc);
                if (filterSettings) {
                    APP_GLOBAL.filterSettings = JSON.parse(filterSettings);
                    this.data.filterSettings = JSON.parse(filterSettings);
                } else {
                    APP_GLOBAL.filterSettings = BeyonityUiUtils.copyObject(APP_GLOBAL.defaultFilterSettings);
                    this.data.filterSettings = BeyonityUiUtils.copyObject(APP_GLOBAL.defaultFilterSettings);
                }
                this.onProcessed();
                return;
            }
        }

        APP_GLOBAL.selectedLanguage = language;

        this._loadLanguageFromServer(api, {action: 'get_language', id: id, lang: language}, () => {
            this.onLanguageLoaded();
            this._loadCustomerData(api, {action: 'get_customer', id: id, lang: language}, () => {
                this.onCustomerLoaded();
                this._loadDataFromServer(api, {id: id, lang: language, t: Date.now()}, () => {
                    this.onDataLoaded();
                    this.loadCustomContent(() => {
                        this.onCustomContentLoaded();
                        this._processData(() => {
                            APP_GLOBAL.defaultFilterSettings = this._getInitialFilterSettings();
                            APP_GLOBAL.filterSettings = BeyonityUiUtils.copyObject(APP_GLOBAL.defaultFilterSettings);
                            this.data.filterSettings = BeyonityUiUtils.copyObject(APP_GLOBAL.defaultFilterSettings);
                            // save the data to the session storage add the id and a timestamp to the key
                            // to be able to identify the data and to be able to reload it after 1h
                            sessionStorage.setItem(`data_${id}_${language}`, JSON.stringify({
                                from: Date.now(),
                                ...APP_GLOBAL
                            }));
                            this.onProcessed();
                        });
                    });
                });
            });
        });
    }


    loadCustomContent = (callback) => {
        if (!this.customContentLoaded && this.data.custom) {
            if (this.data.custom.js) {
                const s = window.document.createElement('script');
                s.src = this.domain + this.data.custom.js + '?lang=' + this.props.language + '&t=' + Date.now();
                window.document.body.appendChild(s);
            }
            if (this.data.custom.css) {
                const s = window.document.createElement('link');
                s.href = this.domain + this.data.custom.css + '?t=' + Date.now();
                s.rel = "stylesheet";
                window.document.head.appendChild(s);
            }
        }
        callback();
    };

    _loadCustomerData = (url, query, callback) => {
        request(url, query)
            .then(
                result => {
                    APP_GLOBAL.customer = result.data;
                    callback();
                },
                error => {
                    this.error = true;
                    this.onError(false);
                }
            );
        callback();
    }

    _loadLanguageFromServer = (url, query, callback) => {
        request(url, query)
            .then(
                result => {
                    APP_GLOBAL.language = result;
                    callback();
                },
                error => {
                    this.error = true;
                    this.onError(false);
                }
            );
    };


    _loadDataFromServer = (url, query, callback) => {
        request(url, query)
            .then(
                result => {
                    this.data = result.data;
                    this.error = false;
                    callback(true);
                },
                error => {
                    this.error = true;
                    this.onError(false);
                }
            );
    };


    getData = () => {
        return this.data;
    }


    /**
     * Array of available categories
     * @type {[string]}
     */
    categories = [];

    /**
     * Object of available types per category
     * @type {{all: [string]}}
     */
    availableTypesPerCategory = {};

    /**
     * This iterates over all items and processes them
     *
     * @private
     */
    _processData = (callback) => {
        // this._addExampleData();

        FormatData.init(
            undefined,
            undefined,
            undefined,
            APP_GLOBAL.language.general.measurementUnits.rooms,
            APP_GLOBAL.language.general.measurementUnits.units);

        const {settings} = this.data,
            {
                languages
            } = APP_GLOBAL.language;

        this.data.settings.headerColumns = 1;



        // setting up an object for the language switch (for the DropdownSelect)
        const languageSwitch = [];
        if (settings && settings.languageSwitch) {
            settings.languageSwitch.forEach(lang => {
                languageSwitch.push({
                    id       : lang,
                    label    : languages[lang].short,
                    itemLabel: languages[lang].long
                })
            });

            this.data.languageSwitchObject = languageSwitch;
        }

        // These will be used while looping though all the items to calculate the values
        // and will be used to display the filters.
        this.data.maxAreaFree = 0;
        this.data.minAreaFree = 0;
        this.data.areaFreeMinMaxByType = {};
        this.data.categoryUsage = {};
        this.data.items.forEach(item => this._processItem(item));

        this.data.items = this._resolveRelatives(this.data.items);
        let mostUsedCategory = this.data.categoryUsage;
        this.data.mostUsedCategory = ( mostUsedCategory[CATEGORY_LIVING] || 0 ) >= ( mostUsedCategory[CATEGORY_COMMERCE] || 0 ) ? CATEGORY_LIVING : CATEGORY_COMMERCE;
        this.data.availableCategories = this.categories;
        this.data.availableTypesPerCategory = this.availableTypesPerCategory;
        APP_GLOBAL.data = BeyonityUiUtils.copyObject(this.data);
        callback();
    }



    /**
     * This processes data for a single item, it also calculates global values which depend on all the items and are
     * defined outside of this function
     * @param item
     * @private
     */
    _processItem = (item) => {

        // address check
        if (item.address === undefined || item.address === null) {
            item.address = "";
        }
        item.pin = /^https?:\/\//.test(item.pin) ? item.pin : this.domain + item.pin
        item.activePin = "https:\/\/portfolio-overview5.beyonity.ch\/data\/5642130A\/assets\/images\/pins\/active.svg"

        // thumbnail

        item.thumbnail += "?a=42"

        const {
            areaFreeMinMaxByType,
        } = item;

        const propertyListTemplate = this.data.itemList; // this contains the info about the properties to display
        const propertyData = this.data.properties // List of available properties and metadata
        let propsList = [
            {name: "", value: ""},
            {name: "", value: ""}
        ];
        //---------  Area Free Total -----------//

        let areaFreeTotal = 0;

        item.areaFreeByType.forEach(category => {
            Object.values(category).forEach(typesArray => {
                typesArray.forEach(type => {
                    let area = Object.values(type)[0];
                    areaFreeTotal += area;
                });
            });
        });

        item.areaFreeTotal = areaFreeTotal;

        if (areaFreeTotal > this.data.maxAreaFree) this.data.maxAreaFree = areaFreeTotal;
        if (areaFreeTotal < this.data.minAreaFree) this.data.minAreaFree = areaFreeTotal;

        //---------  Area Free By Unit -----------//
        //----------------------------------------
        // The following section calculates the min and max areas / values for each available category over all items
        // Meaning : When a type in this item has either a bigger or smaller available area it will replace the current
        // min or max value for this type in the data object. This will be used to display the filters.
        // *This differs* from the `this.data.maxAreaFree` and `this.data.minAreaFree` which are the max and min values
        // of all Units and types of the item in sum. Here we use the min and max values of available single units
        // inside the items
        item.areaFreeMinMaxByType.forEach(category => {
            let catName = Object.keys(category)[0];
            Object.values(category).forEach(typesArray => {
                typesArray.forEach(type => {
                    let typeName = Object.keys(type)[0];
                    let areaMin = Object.values(type)[0].min;
                    let areaMax = Object.values(type)[0].max;
                    if (this.data.areaFreeMinMaxByType[catName] === undefined) {
                        this.data.areaFreeMinMaxByType[catName] = {all: {min: null, max: null}};
                    }
                    if (this.data.areaFreeMinMaxByType[catName][typeName] === undefined) this.data.areaFreeMinMaxByType[catName][typeName] = {
                        min: 0,
                        max: 0
                    };
                    let currentAreaMin = this.data.areaFreeMinMaxByType[catName][typeName].min;
                    let currentAreaMax = this.data.areaFreeMinMaxByType[catName][typeName].max;
                    let allAreaMin = this.data.areaFreeMinMaxByType[catName].all.min;
                    let allAreaMax = this.data.areaFreeMinMaxByType[catName].all.max;

                    // compare current and new area numbers and replace them if bigger / smaller
                    if (areaMin < currentAreaMin || currentAreaMin === null) this.data.areaFreeMinMaxByType[catName][typeName].min = areaMin;
                    if (areaMax > currentAreaMax || currentAreaMax === null) this.data.areaFreeMinMaxByType[catName][typeName].max = areaMax;
                    if (areaMin < allAreaMin || allAreaMin === null) this.data.areaFreeMinMaxByType[catName].all.min = areaMin;
                    if (areaMax > allAreaMax || allAreaMax === null) this.data.areaFreeMinMaxByType[catName].all.max = areaMax;
                });
            });
        });


        //--------- Properties displayed in Sidebar -----------//

        let types = [];
        let total = 0;
        item.availableCategories = [];
        item.availableTypesPerCategory = {};

        item.areaTotalByType.forEach(category => {
            Object.values(category).forEach(typesArray => {
                let catName = Object.keys(category)[0];
                if (!this.categories.includes(catName)) this.categories.push(catName);
                if (!this.availableTypesPerCategory[catName])
                    this.availableTypesPerCategory[catName] = [];
                item.availableCategories.push(catName)
                item.availableTypesPerCategory[catName] = [];
                typesArray.forEach(type => {
                    if (!this.availableTypesPerCategory[catName].includes(Object.keys(type)[0])) this.availableTypesPerCategory[catName].push(Object.keys(type)[0]);
                    item.availableTypesPerCategory[catName].push(Object.keys(type)[0]);
                    total += Object.values(type)[0];
                    types.push(type);
                });
            });
        });


        // sort the types array by the value of each object
        types.sort((a, b) => {
            return Object.values(b)[0] - Object.values(a)[0];
        });

        if (types.length > 2) {
            let sumOfOtherTypes = 0;
            types.slice(1).forEach(type => {
                sumOfOtherTypes += Object.values(type)[0];
            });
            propsList[0] = {
                name: Object.keys(types[0])[0],
                value: FormatData.formatArea(Object.values(types[0])[0])
            };
            propsList[1] = {
                name : APP_GLOBAL.language.categories.others.name,
                value: FormatData.formatArea(sumOfOtherTypes)
            };
        } else {
            types.forEach((type, index) => {
                propsList[index] = {name: Object.keys(type)[0], value: FormatData.formatArea(Object.values(type)[0])};
            });
        }

        // let status = this.data.settings.status;

        item.propsList = propsList;
        item.areaTotal = total;
        //  item.totalBadgeColor = areaFreeTotal === 0 ? status.not_available.color : status.available.color;

        // new clean units information object

        item.areaTotalMinMaxByType = {};
        item.units = {};

        // looping over the categories of this item
        Object.keys(item.availableTypesPerCategory).forEach(category => {

            if (!item.units[category]) item.units[category] = {};
            if (!item.units[category].types) item.units[category].types = {};
            if (!item.units[category].rooms) item.units[category].rooms = {};

            if (!item.units[category].totalArea) item.units[category].totalArea = 0;
            if (!item.units[category].freeArea) item.units[category].freeArea = 0;

            if (!item.units[category].totalUnits) item.units[category].totalUnits = 0;
            if (!item.units[category].freeUnits) item.units[category].freeUnits = 0;

            // counting category usage
            if (!this.data.categoryUsage[category]) this.data.categoryUsage[category] = 0;
            this.data.categoryUsage[category]++;

            // looping over the categories types
            item.availableTypesPerCategory[category].forEach(type => {

                if (!item.units[category].types[type]) {
                    // collecting the rooms
                    let totalRooms = item.roomTypesByType.find(cat => Object.keys(cat)[0] === category)[category].find(t => Object.keys(t)[0] === type)[type];
                    let freeRooms =
                        item.roomTypesFreeByType.find(cat => Object.keys(cat)[0] === category)[category].find(t => Object.keys(t)[0] === type)[type];

                    if (!item.units[category].rooms) item.units[category].rooms = {};


                    Object.keys(totalRooms).forEach(room => {

                        let itemRoom = item.units[category].rooms[room];

                        if (!itemRoom) {
                            item.units[category].rooms[room] = {};
                            itemRoom = item.units[category].rooms[room];
                            itemRoom.totalUnits = 0;
                            itemRoom.freeUnits = 0;
                        }
                        if (!itemRoom.area) {
                            itemRoom.area = {free: 0, total: 0};
                        }
                        if (!itemRoom.numberOfUnits) {
                            itemRoom.numberOfUnits = {free: 0, total: 0};
                        }
                        // area
                        itemRoom.area.total = ( itemRoom.area.total || 0 ) + ( totalRooms[room] || 0 );
                        itemRoom.area.free = ( itemRoom.area.free || 0 ) + ( freeRooms[room] || 0 );

                        // rooms
                        itemRoom.numberOfUnits.total = ( itemRoom.numberOfUnits.total || 0 ) + ( totalRooms[room] || 0 );
                        itemRoom.numberOfUnits.free = ( itemRoom.numberOfUnits.free || 0 ) + ( freeRooms[room] || 0 );

                        // category total
                        itemRoom.totalUnits += totalRooms[room] || 0;
                        itemRoom.freeUnits += freeRooms[room] || 0;
                    });

                    // collecting the types area

                    const areaTotal = item.areaTotalByType.find(cat => Object.keys(cat)[0] === category)[category].find(t => Object.keys(t)[0] === type)[type];
                    const areaFree = item.areaFreeByType.find(cat => Object.keys(cat)[0] === category)[category].find(t => Object.keys(t)[0] === type)[type];

                    let unitsFree = item.unitsFreeCountByType.find(cat => Object.keys(cat)[0] === category)[category].find(t => Object.keys(t)[0] === type)[type];
                    let unitsTotal = item.unitsCountByType.find(cat => Object.keys(cat)[0] === category)[category].find(t => Object.keys(t)[0] === type)[type];

                    item.units[category].totalArea += areaTotal;
                    item.units[category].freeArea += areaFree
                    item.units[category].freeUnits += unitsFree || 0;
                    item.units[category].totalUnits += unitsTotal || 0;

                    item.units[category].types[type] = {
                        area         : {
                            free      : areaFree,
                            total     : areaTotal,
                            freeRange : areaFreeMinMaxByType.find(cat => Object.keys(cat)[0] === category)[category].find(t => Object.keys(t)[0] === type)[type],
                            totalRange: {
                                min: 0,
                                max: areaTotal
                            }
                        },
                        numberOfUnits: {
                            total: unitsTotal,
                            free: unitsFree,
                        }
                    }
                }
            });
        });

        //--------- Icons -----------//

        item.icons = [];
        if (propertyListTemplate.tile1 !== "") {
            item.icons.push({
                color: propertyData[propertyListTemplate.tile1].iconColor,
                name : propertyData[propertyListTemplate.tile1].icon
            })
        }
        if (propertyListTemplate.tile2 !== "") {
            item.icons.push({
                color: propertyData[propertyListTemplate.tile2].iconColor,
                name : propertyData[propertyListTemplate.tile2].icon
            })
        }
        if (propertyListTemplate.tile3 !== "" && item.properties[propertyListTemplate.tile3] !== "") {
            let prop = propertyData[propertyListTemplate.tile3];
            item.icons.push({
                color    : prop.iconColor,
                name     : prop.icon,
                hoverText: `${prop.dsc} : ${item.properties[propertyListTemplate.tile3]}`
            })
        }

        // here we add the icons, only if it's a not to be merged navigator
        if(item.childNavigators === "") {
            if (item.category.includes(CATEGORY_LIVING) && item.category.includes(CATEGORY_COMMERCE)) {
                item.icons.push({
                    color    : "var(--color-text--gray-600)",
                    name     : "home_work",
                    hoverText: APP_GLOBAL.language.categories.commerce.name + " & " + APP_GLOBAL.language.categories.living.name
                })
            } else if (item.category.includes(CATEGORY_LIVING)) {
                item.icons.push({
                    color    : "var(--color-text--gray-600)",
                    name: CATEGORY_ICONS[CATEGORY_LIVING],
                    hoverText: APP_GLOBAL.language.categories.living.name
                })
            } else if (item.category.includes(CATEGORY_COMMERCE)) {
                item.icons.push({
                    color    : "var(--color-text--gray-600)",
                    name: CATEGORY_ICONS[CATEGORY_COMMERCE],
                    hoverText: APP_GLOBAL.language.categories.commerce.name
                })
            }
        }

        //--------- Web Path / Links -----------//
        item.webPath = {};
        item.webPath[item.category] = item.web_path;

        if (item.linkOverride && item.linkOverride !== "") {
            // if not start with https:// add https://
            let itemLink = item.linkOverride;
            if (!/^https?:\/\//.test(itemLink)) {
                itemLink = "https://" + itemLink;
            }
            item.webPath[item.category] = itemLink;
        }
    }


    /**
     * The items can be relative to each other for that they have a property called childNavigators.
     * The Child navigator list contains comma separated ids of items which are relative to the current item.
     *
     * If they are relative to each other they should be displayed in the same box. This function resolves the
     * relatives and adds them to the current item.
     *
     * Items which are children will have a "-" in their childNavigators property.
     *
     * Items which aren't relative to any other item will have a "" (empty string) in their childNavigators property.
     *
     * @param items
     * @private
     */
    _resolveRelatives = (items) => {
        let result = [];

        items.forEach(item => {
            if (!item.childNavigators) {
                result.push(item);
                return;
            }
            // this is a child item and should not be displayed
            if (item.childNavigators === "-") {
                return;
            }
            // this item has no relatives
            if (item.childNavigators === "") {
                result.push(item);
                return;
            }
            // this item has relatives
            let relatives = item.childNavigators.split(",");
            let children = [];

            // loop through the relatives and find the corresponding items
            relatives.forEach(relative => {
                let child = items.find(item => item.id === relative);
                if (child) {
                    children.push(child);
                }
            });

            result.push(this.__mergeChildren(item, children));

        });

        return result;
    }


    /**
     * THis method merges the children with the base object
     * this works the amy that there will be a wrapper object created containing the
     * properties which re the same for all children and then the children and the base object
     * like the address, dc, etc... For things like total free area and the type array they should be merged and added
     * to the wrapper object
     *
     * in the end there should be a list of all the objects, the base object adn the child objects in their ful form
     * @param item
     * @param children
     * @returns {undefined}
     * @private
     */
    __mergeChildren(item, children) {
            let wrapper = {
                id: item.id,
                type: item.type,
                company: item.company,
                category: item.category,
                priceType: item.priceType,
                childNavigators: item.childNavigators,
                properties: item.properties,
                dsc: item.dsc,
                thumbnail: item.thumbnail,
                location: item.location,
                unitsCount: item.unitsCount,

                areaFreeMinMaxByType: item.areaFreeMinMaxByType,
                pin: item.pin,
                address: item.address,
                activePin: item.activePin,
                areaFreeTotal: item.areaFreeTotal,
                availableCategories: item.availableCategories,
                availableTypesPerCategory: item.availableTypesPerCategory,
                units    : item.units,
                propsList: item.propsList,
                areaTotal: item.areaTotal,
                icons: item.icons,
                webPath: item.webPath
            };



        children.forEach(child => {

            wrapper.webPath[child.category] = child.webPath[child.category];

                wrapper.unitsCount += child.unitsCount;
            // wrapper.unitsFreeCount += child.unitsFreeCount;

            /*
             areaFreeByType: item.areaFreeByType,

                child.areaFreeByType.forEach(childCategory => {
                    let childCategoryName = Object.keys(childCategory)[0];
                    let parentCategory = wrapper.areaFreeByType.find(category => Object.keys(category)[0] === childCategoryName);

                    if (!parentCategory) {
                        parentCategory = {[childCategoryName]: []};
                        wrapper.areaFreeByType.push(parentCategory);
                    }

                    childCategory[childCategoryName].forEach(childType => {
                        let childTypeName = Object.keys(childType)[0];
                        let parentType = parentCategory[childCategoryName].find(type => Object.keys(type)[0] === childTypeName);

                        if (!parentType) {
                            parentCategory[childCategoryName].push(childType);
                        } else {
                            parentType[childTypeName] += childType[childTypeName];
                        }
                    });
                });

             */

            /*
             http://localhost:3000/?id=5642130A
                child.areaTotalByType.forEach(childCategory => {
                    let childCategoryName = Object.keys(childCategory)[0];
                    let parentCategory = wrapper.areaTotalByType.find(category => Object.keys(category)[0] === childCategoryName);

                    if (!parentCategory) {
                        parentCategory = {[childCategoryName]: []};
                        wrapper.areaTotalByType.push(parentCategory);
                    }
                    childCategory[childCategoryName].forEach(childType => {
                        let childTypeName = Object.keys(childType)[0];
                        let parentType = parentCategory[childCategoryName].find(type => Object.keys(type)[0] === childTypeName);

                        if (!parentType) {
                            parentCategory[childCategoryName].push(childType);
                        } else {
                            parentType[childTypeName] += childType[childTypeName];
                        }
                    });
                });
             */

                // areaFreeMinMaxByType
                child.areaFreeMinMaxByType.forEach(childCategory => {
                    let childCategoryName = Object.keys(childCategory)[0];
                    let parentCategory = wrapper.areaFreeMinMaxByType.find(category => Object.keys(category)[0] === childCategoryName);

                    if (!parentCategory) {
                        parentCategory = {[childCategoryName]: []};
                        wrapper.areaFreeMinMaxByType.push(parentCategory);
                    }
                    childCategory[childCategoryName].forEach(childType => {
                        let childTypeName = Object.keys(childType)[0];
                        let parentType = parentCategory[childCategoryName].find(type => Object.keys(type)[0] === childTypeName);

                        if (!parentType) {
                            parentCategory[childCategoryName].push(childType);
                        } else {
                            parentType[childTypeName].min = Math.min(parentType[childTypeName].min, childType[childTypeName].min);
                            parentType[childTypeName].max = Math.max(parentType[childTypeName].max, childType[childTypeName].max);
                        }
                    });
                });

                Object.keys(child.units).forEach(category => {
                    // merging category
                    let wrapperCategory = wrapper.units[category];
                    let childCategory = child.units[category];

                    if (!wrapperCategory) wrapperCategory = {};

                    wrapperCategory.totalArea = ( wrapperCategory.totalArea || 0 ) + ( childCategory.totalArea || 0 );
                    wrapperCategory.freeArea = ( wrapperCategory.freeArea || 0 ) + ( childCategory.freeArea || 0 );
                    wrapperCategory.freeUnits = ( wrapperCategory.freeUnits || 0 ) + ( childCategory.freeUnits || 0 );
                    wrapperCategory.totalUnits = ( wrapperCategory.totalUnits || 0 ) + ( childCategory.totalUnits || 0 );

                    Object.keys(childCategory.types).forEach(type => {
                        if (!wrapperCategory.types) {
                            wrapperCategory.types = {};
                        }
                        if (!wrapperCategory.types[type]) {
                            wrapperCategory.types[type] = {area: {}};
                        }

                        let wrapperType = wrapperCategory.types[type];
                        let childType = childCategory.types[type];
                        if (!wrapperType.numberOfUnits) wrapperType.numberOfUnits = {free: 0, total: 0};

                        wrapperType.area.free = ( wrapperType.area.free || 0 ) + childType.area.free;
                        wrapperType.area.total = ( wrapperType.area.total || 0 ) + childType.area.total;
                        wrapperType.area.freeRange = {
                            min: Math.min(wrapperType.freeRange?.min || 0, childType.freeRange?.min || 0),
                            max: Math.max(wrapperType.freeRange?.max || 0, childType.freeRange?.max || 0)
                        };
                        wrapperType.numberOfUnits.free += childType.numberOfUnits?.free || 0;
                        wrapperType.numberOfUnits.total += childType.numberOfUnits?.total || 0;

                        wrapperCategory.types[type] = wrapperType;
                        wrapper.units[category] = wrapperCategory;

                    });

                    Object.keys(childCategory.rooms).forEach(room => {
                        if (!wrapperCategory.rooms) {
                            wrapperCategory.rooms = {};
                        }

                        let wrapperRoom = wrapperCategory.rooms[room] || {}
                        let childRoom = childCategory.rooms[room];

                        if (!wrapperRoom.numberOfUnits) wrapperRoom.numberOfUnits = {free: 0, total: 0};
                        if (!wrapperRoom.area) wrapperRoom.area = {free: 0, total: 0};

                        wrapperRoom.area.free = ( wrapperRoom.area.free || 0 ) + ( childRoom?.area?.free || 0 );
                        wrapperRoom.area.total = ( wrapperRoom.area.total || 0 ) + ( childRoom?.area?.total || 0 );
                        wrapperRoom.numberOfUnits.free += childRoom?.numberOfUnits?.free || 0;
                        wrapperRoom.numberOfUnits.total += childRoom?.numberOfUnits?.total || 0;

                        wrapperCategory.rooms[room] = wrapperRoom;
                        wrapper.units[category] = wrapperCategory;
                    });
                });

                // adding up total
                wrapper.total += child.total;
                wrapper.areaFreeTotal += child.areaFreeTotal;
                wrapper.areaTotal += child.areaTotal;

                let status = this.data.settings.status;
                // wrapper.totalBadgeColor = wrapper.areaFreeTotal === 0 ? status.not_available.color :
                // status.available.color;

                child.availableCategories.forEach(category => {
                    if (!wrapper.availableCategories.includes(category)) {
                        wrapper.availableCategories.push(category);
                    }
                });

                Object.keys(child.availableTypesPerCategory).forEach(category => {
                    if (!wrapper.availableTypesPerCategory[category]) {
                        wrapper.availableTypesPerCategory[category] = [];
                    }
                    child.availableTypesPerCategory[category].forEach(type => {
                        if (!wrapper.availableTypesPerCategory[category].includes(type)) {
                            wrapper.availableTypesPerCategory[category].push(type);
                        }
                    });
                });
                wrapper.category = wrapper.category.concat(child.category);

            });

        if (wrapper.category.includes(CATEGORY_LIVING) && wrapper.category.includes(CATEGORY_COMMERCE)) {
                wrapper.icons.push({
                    color    : "var(--color-text--gray-600)",
                    name     : "home_work",
                    hoverText: APP_GLOBAL.language.categories.commerce.name + " & " + APP_GLOBAL.language.categories.living.name
                })
        } else if (wrapper.category.includes(CATEGORY_LIVING)) {
                wrapper.icons.push({
                    color    : "var(--color-text--gray-600)",
                    name: CATEGORY_ICONS[CATEGORY_LIVING],
                    hoverText: APP_GLOBAL.language.categories.living.name
                })
        } else if (wrapper.category.includes(CATEGORY_COMMERCE)) {
                wrapper.icons.push({
                    color    : "var(--color-text--gray-600)",
                    name: CATEGORY_ICONS[CATEGORY_COMMERCE],
                    hoverText: APP_GLOBAL.language.categories.living.name
                })
            }

        return wrapper;
    }



    _getInitialFilterSettings = () => {
        return BeyonityUiUtils.copyObject(
            {
                sort                      : {
                    by : "dsc",
                    asc: true
                },
                category                  : this.data.mostUsedCategory,
                type                      : ["all"],
                location                  : {
                    name  : false,
                    lat   : false,
                    lng   : false,
                    radius: 50
                },
                properties: {
                    property1: {
                        from: 0,
                        tp  : 0
                    }
                },
                categorySpecificProperties: {
                    living  : {},
                    commerce: {
                        area: {
                            from: this.data.minAreaFree,
                            to  : this.data.maxAreaFree
                        }
                    }
                },
                propertyFilters           : false
            }
        );
    }
}