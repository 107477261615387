import React, {Component} from "react";

import styles from "./index.module.scss";
import {FormatData} from "../../utils";
import {APP_GLOBAL} from "../App/DataManager";
import {
    BeyBroadcaster,
    BeyonityUiUtils,
    GoogleMap,
    InfoCard,
    ProjectItemCard,
    SequencialFadeIn
} from "@beyonityeu/beyonity-ui-buttons";
import * as propTypes from "prop-types";
import {CATEGORY_COMMERCE, CATEGORY_LIVING, FILTER_SETTINGS_CHANGE_EVENT_TOPIC} from "../FilterBox";




/**
 * This component houses the sidebars list of items.
 * It's also responsible for applying filters and sorting it.
 *
 * @author [Willi Boelke](willi-dev@outlook.com)
 */
class ItemCardList extends Component {


    constructor(props) {
        super(props);
        this.state = {
            items         : props.items,
            filterSettings: null,
            hoveredItem: null
        };
    }


    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!BeyonityUiUtils.compareObjects(nextState.filterSettings, this.state.filterSettings)) {
            this._filterItems(nextState.filterSettings);
            return false;
        }
        if (!BeyonityUiUtils.compareObjects(nextState.items, this.state.items)) {
            return true;
        }
        return true;
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.items !== this.props.items || prevProps.filterSettings !== this.props.filterSettings) {
            this.setState({
                items: this.props.items,
                filterSettings: this.props.filterSettings
            });
        }
    }


    componentDidMount() {
        BeyBroadcaster.instance.subscribe(FILTER_SETTINGS_CHANGE_EVENT_TOPIC, this);
        this._filterItems(this.state.filterSettings);
    }


    componentWillUnmount() {
        BeyBroadcaster.instance.unsubscribe(FILTER_SETTINGS_CHANGE_EVENT_TOPIC, this);
    }


    render() {

        const {
            items,
        } = this.state;

        return (
            <div className={styles.itemCardList}>
                <SequencialFadeIn inBetweenDelay={50} duration={400}>
                    {items && items.length > 0 ?
                        items.map((item, i) => this._renderItem(item, i))
                        :
                        <InfoCard text={"Es konnten leider keine passenden Liegenschaften gefunden werden"}/>
                    }
                </SequencialFadeIn>
            </div>
        );
    }


    _renderItem = (item, index = null) => {
        const {hoveredItem} = this.state;
        const {onItemClick} = this.props;
        return ItemCardList.renderProjectItemCard(item, index, hoveredItem, onItemClick, this.state.filterSettings);
    }

    /**
     * This returns a `ProjectItemCard` for the given `item`, which maybe a "Navigator" or a
     * "Configurator", if the item is mapped from an object the index parameter is used as the
     * elements `key`. It can be set to false when no array is used. This will decouple the
     * item from the `activeItem` state and thus prevent it from being highlighted as active.
     * (For the Tooltip)
     * @param item {Object} | {boolean}
     * The item to render
     * @param index {number|boolean}
     * The index of the item in the array or `false`
     * @param hoveredItem
     * @param onItemClick
     * @param filterSettings
     * @return {JSX.Element}
     */
    static renderProjectItemCard = (item, index = 0, hoveredItem = false, onItemClick, filterSettings) => {
        const {
            thumbnail,
            dsc,
            propsList,
            icons,
            areaFreeTotal,
        } = item;

        const {language} = APP_GLOBAL;

        let status = APP_GLOBAL.data.settings.status;
        let BadgeContent = FormatData.formatArea(areaFreeTotal);
        let BadgeColor = status.not_available.color;

        if (filterSettings) {
            if (filterSettings.category === CATEGORY_COMMERCE) {
                BadgeContent = item.units[filterSettings.category].freeArea;
                if (BadgeContent > 0) {
                    BadgeColor = status.available.color;
                }
                BadgeContent = FormatData.formatArea(BadgeContent);
            }
            if (filterSettings.category === CATEGORY_LIVING) {
                BadgeContent = item.units[filterSettings.category].freeUnits;
                if (BadgeContent > 0) {
                    BadgeColor = status.available.color;
                }
                BadgeContent = `${BadgeContent} ${language.general.free}`;
            }
        }


        return (
            <div key={`item_${index}`}>
                <ProjectItemCard imageLink={thumbnail}
                                 infos={""}
                                 name={dsc}
                                 icons={icons}
                                 badgeContent={BadgeContent}
                                 badgeColor={BadgeColor}
                                 active={item.id === hoveredItem}
                                 properties={[propsList[0], propsList[1]]}
                                 onClick={() => {
                                     onItemClick && onItemClick(item, true);
                                 }}
                />
            </div>
        );
    }


    //
    // --------- Filtering -----------
    //


    _filterItems = (filterSettings) => {
        if (!filterSettings) return;
        let tmpItems = BeyonityUiUtils.copyObject(APP_GLOBAL.data.items);
        const {location, category, type, sort} = filterSettings;

            //---- Sending location and radius to the GoogleMap component ----//
            if (location.lat !== null && location.lng !== null) {
                BeyBroadcaster.instance.publish(GoogleMap.BROADCAST_MAP_CIRCLE_POSITION_TOPIC,
                    {
                        lat: filterSettings.location.lat,
                        lng: filterSettings.location.lng,
                        radius: filterSettings.location.radius,
                    });
            }

        tmpItems = tmpItems.filter(item => {

            //---- Filtering items by distance ----//
            if (location.lat !== false && location.lat !== null && location.lng !== null) {
                if (BeyonityUiUtils.distanceBetweenCoordinates(
                    filterSettings.location.lat,
                    filterSettings.location.lng,
                    item.location.lat,
                    item.location.lng
                ) > filterSettings.location.radius) return false;
            }

            //--------- Sort out by category -----------//
            if (category !== "all") {
                if (!item.availableCategories.includes(category)) return false;
                if (!type.some(typeToFilter => item.availableTypesPerCategory[category].includes(typeToFilter))) return false;
            }
            //--------- category specific -----------//

            if (category.includes("commerce") && filterSettings.categorySpecificProperties.commerce.area) {
                if (!this.isTypeWithinAreaRange(item, filterSettings)) return false;
            }

            //--------- Sorting -----------//
            if (filterSettings.propertyFilters && filterSettings.propertyFilters !== {}) {
                const propertyFilter = filterSettings.propertyFilters;
                const itemProperties = item.properties;
                for (let property in propertyFilter) {
                    if (propertyFilter[property].type === "number") {
                        if (itemProperties[property] === "" || itemProperties[property] > propertyFilter[property].max) return false;
                    } else if (propertyFilter[property].type === "options") {
                        if (itemProperties[property] === "" || !propertyFilter[property].options.includes(itemProperties[property])) return false;
                    } else if (propertyFilter[property].type === "boolean") {
                        if (!propertyFilter[property].value !== ( itemProperties[property] === "" )) return false;
                        else if (propertyFilter[property].value === ( itemProperties[property] === "" )) return false;
                    }
                }
            }
            return true;
        });
      this.props.onMarkersChanged(BeyonityUiUtils.copyObject(this._generateMarkerListFromItems(tmpItems)));

      BeyonityUiUtils.deepSort(tmpItems, [[sort.by, sort.asc]]);
        this.setState({
            items: tmpItems
        });
    }


    isTypeWithinAreaRange(item, filterSettings) {
        const selectedCategory = filterSettings.category;
        const selectedTypes = filterSettings.type;
        const areaFreeMinMaxByType = item.areaFreeMinMaxByType;
        const categoryAreaRange = filterSettings.categorySpecificProperties.commerce.area;

        for (const type of selectedTypes) {
            // skip the type "all" since it is not a real type
            if (type === "all") continue;
            let min = 0;
            let max = 0;
            let found = false;
            for (const categoryObj of areaFreeMinMaxByType) {
                if (categoryObj[selectedCategory]) {
                    for (const typeObj of categoryObj[selectedCategory]) {
                        if (typeObj[type]) {
                            min = typeObj[type].min;
                            max = typeObj[type].max;
                            found = true;
                            break;
                        }
                    }
                }
            }
            if (found && ( +min >= +categoryAreaRange.from ) && ( +max <= +categoryAreaRange.to )) {
                return true; // At least one type falls within the area range
            }
        }
        return false; // No selected type falls within the area range
    }


    _generateMarkerListFromItems = (items) => {
        const {
            onItemClick,
            onItemHover
        } = this.props;



        let result = [];
        items.forEach(item => {
            result.push({
                lat         : +item.location.lat,
                lng         : +item.location.lng,
                id          : item.id,
                name        : item.dsc,
                category: item.category,
                icon      : item.pin,
                activeIcon: item.activePin,
                onClick     : () => {
                    onItemClick(item, false);
                },
                onMouseEnter: () => {
                    this.setState({
                        hoveredItem: item.id
                    })
                    onItemHover(item, true);
                },
                onMouseLeave: () => {
                    this.setState({
                        hoveredItem: null
                    })
                    onItemHover(item, false);
                },
            });
        });
        return result;
    }


    onReceive = (topic, data) => {
        if (topic === FILTER_SETTINGS_CHANGE_EVENT_TOPIC) {
            this.setState({
                filterSettings: BeyonityUiUtils.copyObject(data)
            });
        }
    }

}

ItemCardList.defaultProps = {};

ItemCardList.propTypes = {
    items        : propTypes.arrayOf(propTypes.object),
    filterSettings: propTypes.object,
    onItemClick  : propTypes.func,
    onItemHover: propTypes.func,
};

export default ItemCardList;