import React, {Component} from "react";

import styles from "./index.module.scss";
import {SIDEBAR_TOGGLE_TOPIC} from "../Sidebar";
import {MAP_TYPE_CHANGE_TOPIC} from "../App";
import {APP_GLOBAL} from "../App/DataManager";
import {
    BeyBroadcaster,
    BeyonityUiUtils,
    Button,
    Dropdown,
    DropdownSelect,
    Icon,
    RemovableButton,
    SelectListItem,
    Slider
} from '@beyonityeu/beyonity-ui-buttons';


import {FILTER_BOX_TOGGLE_EVENT_TOPIC, FILTER_SETTINGS_CHANGE_EVENT_TOPIC} from "../FilterBox";
import * as PropTypes from "prop-types";
import ScrollHorizontal from "../ScrollHorizontal";




Icon.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
    name: PropTypes.any
};

/**
 * This menu will e displayed in the top left if the player.
 * It holds all the function toggles such as map mode, full screen etc. as well as
 * the currently set filters.
 *
 * @author [Willi Boelke](willi-dev@outlook.com)
 */
class FunctionsMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mapType: true,
            filterSettings: {}
        }

        BeyBroadcaster.instance.subscribe(FILTER_SETTINGS_CHANGE_EVENT_TOPIC, (topic, data) => {
            this.setState({filterSettings: BeyonityUiUtils.copyObject(data)})
        });
    }


    render() {
        const {mapType} = this.state,
            {languageSwitchObject} = APP_GLOBAL.data,
            {
                map
        } = APP_GLOBAL.language;


        return (
            <div className={styles.functions}>
                <ScrollHorizontal className={styles.horizontalScroll}>
                    <Button size={"s"} className={styles.function} iconLeft={"fullscreen"} onClick={
                        () => {
                            BeyBroadcaster.instance.publish(SIDEBAR_TOGGLE_TOPIC, {});
                        }
                    }/>
                    {languageSwitchObject.length > 1 &&
                        <div className={styles.function}>
                            <DropdownSelect
                                size={"s"}
                                className={styles.function}
                                initialItem={APP_GLOBAL.selectedLanguage}
                                onSelect={(item) => {
                                    let url = new URL(window.location.href);
                                    url.searchParams.set('lang', item.id);
                                    window.location.href = url.href;
                                }}
                                options={languageSwitchObject}
                                renderToggle={(props) => {
                                    props.iconRight = "";
                                    return <Button {...props}/>
                                }}
                            >
                                {(props) => {
                                    const {
                                        item,
                                    } = props;

                                    let dispItem = BeyonityUiUtils.copyObject(item);
                                    dispItem.label = item.itemLabel;

                                    return <SelectListItem {...props} item={dispItem}/>
                                }}
                            </DropdownSelect>
                        </div>

                    }
                    <Button size={"s"} className={styles.function} iconLeft={"map"}
                            label={!mapType ? map.controls.map : map.controls.satellite} onClick={() => {
                        this.setState({mapType: !mapType}, () => {
                            BeyBroadcaster.instance.publish(MAP_TYPE_CHANGE_TOPIC, {mapType: mapType ? "roadmap" : "hybrid"});
                        });
                    }
                    }/>
                    <Button size={"s"} className={styles.function} iconLeft={"filter_list"}
                            label={APP_GLOBAL.language.filterSort.title} onClick={
                        () => {
                            BeyBroadcaster.instance.publish(FILTER_BOX_TOGGLE_EVENT_TOPIC, {});
                        }
                    }/>
                    {this._renderFilterSettings(styles)}
                </ScrollHorizontal>
            </div>
        );
    }


    _renderFilterSettings(styles) {

        const {filterSettings} = this.state;

        const {filterSort} = APP_GLOBAL.language;

        if (filterSettings.location && filterSettings.location.lat !== false) {
            return (
                <Dropdown size={"s"}
                          icon={"location_on"}
                          className={styles.function}
                          toggleComponent={<RemovableButton
                              onCloseClick={() => {
                                  let newFilterSettings = this.state.filterSettings;
                                  newFilterSettings.location = {
                                      lat   : false,
                                      lon   : false,
                                      radius: 5,
                                      name  : filterSort.filter.map.circumcircle

                                  };
                                  BeyBroadcaster.instance.publish(FILTER_SETTINGS_CHANGE_EVENT_TOPIC, BeyonityUiUtils.copyObject(this.state.filterSettings));
                                  this.setState({filterSettings: newFilterSettings});
                              }}
                              size={"s"}
                              buttonVariant={"main"}
                              buttonType={"secondary"}
                              iconLeft={"location_on"}
                              label={filterSettings.location.name + " - " +
                                  filterSettings.location.radius + " " +
                                  filterSort.filter.map.circumcircleUnit}/>}
                          label={filterSettings.location.name + " - " + filterSettings.location.radius + " " + +" " + filterSort.filter.map.circumcircleUnit}
                >

                    <div className={styles.filterDropdowns}>


                        <div className={styles.filterInfo}>
                            <div className={styles.left}>
                                <Icon size={"s"}
                                      color={`var(--color-text--gray-600)`}
                                      name={"radar"}/>
                                <span>{filterSort.filter.map.circumcircle}</span>
                            </div>
                            <div className={styles.right}>
                                {filterSettings.location.radius + filterSort.filter.map.circumcircleUnit}
                            </div>
                        </div>
                        <Slider minValue={5}
                                maxValue={200}
                                value={filterSettings.location.radius}
                                steps={5}
                                onChange={(value) => {
                                    let newFilterSettings = filterSettings;
                                    newFilterSettings.location.radius = value;
                                    this.setState({filterSettings: newFilterSettings});
                                    BeyBroadcaster.instance.publish(FILTER_SETTINGS_CHANGE_EVENT_TOPIC, BeyonityUiUtils.copyObject(this.state.filterSettings));
                                    sessionStorage.setItem("filterSettings" + APP_GLOBAL.data.dsc, JSON.stringify(this.state.filterSettings));
                                }
                                }></Slider>
                    </div>
                </Dropdown>
            )
        }
    }
}

FunctionsMenu.defaultProps = {};

FunctionsMenu.propTypes = {};

export default FunctionsMenu;