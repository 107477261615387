import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import './fonts.js';

import './normalize.css';
import '@beyonityeu/beyonity-ui-buttons/dist/esm/styles/index.min.css';
import './fonts.css';
import './index.css';


// IMPORTANT - for development use &env=[de|...] for non ch-projects | in config change version | in Excel change
// version & email


const location = window.document.location,
    query = new URLSearchParams(location.search);

let tld = location.hostname.split('.').pop(),
    subdomain = window.location.hostname.split('.').slice(0, -2).join('.'),
    domain = window.location.hostname.split('.').slice(1, -1).join('.');

if (tld === 'localhost') {
    tld = query.get('env') || 'ch';
    domain = 'beyonity';
    subdomain = 'portfolio-overview5-dev';
}


let language = query.get('lang') || query.get('language') || `de-CH`;

if (language.startsWith("ch")) {
    language = 'de-CH'
}

/**
 * The `overrideId` is used to override the normal url parameter `id` for custom domain
 * usage. This variable will be defined from PHP side, if it is set it is likely that no `id`
 * parameter is available at all
 */

ReactDOM.createRoot(document.getElementById('root')).render(
    <App
        domain={`https://${subdomain}.${domain}.${tld}/`}
        apiSlug="api/"
        id={( typeof overrideId !== `undefined` && overrideId ) ? overrideId : query.get('id')}
        uid={query.get('uid')}
        language={language}
    />
);