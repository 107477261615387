import React, {Component} from 'react';
import {ReactComponent as Brand} from './Beyonity.svg';
import {Link} from '@beyonityeu/beyonity-ui-buttons';

import styles from './index.module.scss';




class Footer extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        const {children, brand} = this.props;

        return (
            <div className={styles.footer}>
                <div className={styles.links}>{children}</div>
                <div className={styles.brand}>
                    <div className="textTruncate">Powered by</div>
                    <div><Link href={`https://beyonity.${brand}/`}><Brand/></Link></div>
                </div>
            </div>
        );
    }

}

export default Footer;
