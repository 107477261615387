import React, {Component} from 'react';
import {ApplicationToolbar,} from '@beyonityeu/beyonity-ui-buttons';
import styles from './index.module.scss';




class SidebarBody extends Component {

    constructor(props) {
        super(props);
        this.bodyRef = React.createRef();
    }


    render() {
        const {children, className, renderTools} = this.props;

        return (
            <div
                className={`${styles.body} scrollbar scrollbar--light ${className}`}
                ref={this.bodyRef}
            >
                <div className={styles.toolbar}>
                    <ApplicationToolbar>
                        {renderTools()}
                    </ApplicationToolbar>
                </div>
                {children}
            </div>
        );
    }

}

export default SidebarBody;
