import {ContentTile} from "@beyonityeu/beyonity-ui-buttons";
import {Component} from "react";
import "./BoxArea.css";




class BoxArea extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
        const {
            children,
            title,
            className
        } = this.props;

        return <ContentTile className={"beyonity-ui--box-area " + className} elevation={"E000"} borderRadius={"R400"}>
            <div className={"beyonity-ui--box-area__title beyonity-ui--text__large-highlighted "}>
                {title}
            </div>
            <div className={"beyonity-ui--box-area__content"}>
                {children}
            </div>
        </ContentTile>
    }
}

export default BoxArea;