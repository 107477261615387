import React from 'react';
import {replaceMarkedStringWith, request} from './../../utils';
import ContentBox from './../ContentBox';

import {
    Button,
    CheckboxLabeled,
    CheckInput,
    ContactTile,
    Input,
    Label,
    Link,
    RadioButton,
    RadioButtonGroup,
    TextArea
} from '@beyonityeu/beyonity-ui-buttons';


import styles from './index.module.scss';
import {APP_GLOBAL} from "../App/DataManager";
import BoxArea from "../BoxArea/BoxArea";




class ContactBox extends ContentBox {

    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            error   : false
        };
    }


    componentWillUnmount() {
        super.componentWillUnmount();
    }


    componentDidMount() {
        super.componentDidMount();
    }

    validate = form => {
        const inputs = form.elements;
        let errors = false;
        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].required && !inputs[i].value) {
                inputs[i].classList.add('beyonity-ui-input-field--error');
                errors = true;
            }
            switch (inputs[i].type) {
                case 'email':
                    if (!Input.EMAIL_VALIDATOR.test(inputs[i].value)) {
                        inputs[i].classList.add('beyonity-ui-input-field--error');
                        errors = true;
                    }
                    break;
            }
        }
        return !errors;
    };

    send = e => {
        //e.persist();
        e.preventDefault();
        const DOMForm = e.target;
        if (this.validate(DOMForm)) {
            const {action} = this.props,
                formData = new FormData(DOMForm);
            request(action + '?action=send_contact', formData, 'POST')
                .then(
                    result => {
                        this.close();
                        this.setState({error: !result.status}, () => this.openResponse(250));
                    },
                    error => this.setState({error: true})
                );
        }
    };

    onFocus = e => e.target.classList.remove('beyonity-ui-input-field--error');


    open() {
        super.open();
    }


    close(callback) {
        super.close(callback);
    }


    openResponse(delay) {
        super.open(delay);
    }


    closeResponse() {
        super.close();
    }



    renderHeader() {
        super.renderBody();
        return this.props.title;
    }


    renderBody() {
        super.renderBody();
        const {asp, id, language, policyLinks} = this.props, lang = APP_GLOBAL.language.contact;
        const {disabled, error} = this.state;
        return (
            <form className={styles.form}
                  onSubmit={this.send}
                  noValidate>
                {asp &&
                    <ContactTile
                        elevation={"E300"}
                        className={styles.asp}
                        image={asp.image}
                        data={asp.data}
                    />
                }
                <input type="hidden" name="id" value={id}/>
                <input type="hidden" name="language" value={language}/>
                <BoxArea title={lang.form.blocks.personal.headline}>
                    <div className={styles.contactContentTile}>
                        <Label>{lang.form.blocks.personal.salutation.label}</Label>
                        <RadioButtonGroup fullWidth>
                            {Object.keys(lang.form.blocks.personal.salutation.fields).map(k =>
                                <RadioButton
                                    key={k}
                                    label={lang.form.blocks.personal.salutation.fields[k]}
                                    value={k}
                                    defaultChecked={k === 'none'}
                                    name="salutation"
                                    pill
                                />
                            )}
                        </RadioButtonGroup>
                        <div>
                            <Label
                                htmlFor="ContactFirstname"
                                label={lang.form.blocks.personal.firstname.label}
                                required
                            />
                            <Input
                                id="ContactFirstname"
                                name="firstname"
                                onFocus={this.onFocus}
                                required
                            />
                        </div>
                        <div>
                            <Label
                                htmlFor="ContactLastname"
                                label={lang.form.blocks.personal.lastname.label}
                                required
                            />
                            <Input
                                id="ContactLastname"
                                name="lastname"
                                onFocus={this.onFocus}
                                required
                            />
                        </div>
                    </div>
                </BoxArea>

                <BoxArea title={lang.form.blocks.contact.headline}>
                    <div className={styles.contactContentTile}>
                        <div className={styles.paragraph}>
                            <CheckInput
                                label={lang.form.blocks.contact.email.label}
                                name="email"
                                type="email"
                                onFocus={this.onFocus}
                                checked
                                required
                            />
                        </div>
                        <div className={styles.paragraph}>
                            <CheckInput
                                label={lang.form.blocks.contact.phone.label}
                                name="phone"
                                type="phone"
                                onFocus={this.onFocus}
                            />
                        </div>
                    </div>
                </BoxArea>

                <BoxArea title={lang.form.blocks.message.headline}>
                    <div className={styles.contactContentTile}>
                        <div className={styles.paragraph}>
                            <Input
                                placeholder={lang.form.blocks.message.subject.label}
                                name="subject"
                                onFocus={this.onFocus}
                            />
                        </div>
                        <div className={styles.paragraph}>
                            <TextArea
                                placeholder={lang.form.blocks.message.message.label}
                                name="message"
                                onChange={() => {
                                }}
                                onFocus={this.onFocus}
                            />
                        </div>
                    </div>
                </BoxArea>

                <CheckboxLabeled
                    label={replaceMarkedStringWith(lang.form.blocks.send.privacyPolicy.label, policyLinks, (i, c) =>
                        <Link key={i} href={policyLinks[i]} primary>{c}</Link>)}
                    className={styles.footerLabel}
                    name="privacy"
                    value="1"
                                    onChange={e => this.setState({disabled: !e.target.checked})}
                                    required
                                />
                                <Button
                                    label={lang.form.blocks.send.send.label}
                                    className={styles.footerButton}
                                    type="submit"
                                    disabled={disabled}
                                    size="l"
                                    buttonVariant={"main"}
                                    buttonType={"primary"}
                                    centered
                                    fullWidth
                                />

            </form>
        );
    }

}

export default ContactBox;
