import React, {Component} from 'react';
import {BeyonityUiUtils} from '@beyonityeu/beyonity-ui-buttons';

import styles from './index.module.scss';




class ContentBoxHeader extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        const {children, className} = this.props;

        return (
            <div className={BeyonityUiUtils.generateClassNames([styles.footer, className])}>
                {children}
            </div>
        );
    }

}

export default ContentBoxHeader;
