import React, {Component} from 'react';
import "./OptionsHeader.css"
import PropTypes from "prop-types";
import {BeyonityUiUtils} from "@beyonityeu/beyonity-ui-buttons";




class OptionsHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
        const {
            bottomSeparator
        } = this.props;

        const classes = BeyonityUiUtils.generateClassNames([
            "beyonity-ui--options-header",
            bottomSeparator && "beyonity-ui--options-header__bottom-separator"
        ]);
        return (
            <div className={classes}>
                {this.props.children.map((child, index) => {
                        return (
                            <React.Fragment key={`fragment_${index}`}>
                                <div className={"beyonity-ui--options-header-item"} key={`item_${index}`}>
                                    {child}
                                </div>
                                {index < this.props.children.length - 1 &&
                                    <div key={`separator_${index}`} className={"beyonity-ui--options-header__separator"}/>}
                            </React.Fragment>
                        )
                    }
                )}
            </div>
        );
    }


}

PropTypes.propTypes = {
    children       : PropTypes.array,
    bottomSeparator: PropTypes.bool
}

export default OptionsHeader;
