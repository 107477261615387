import React from 'react';
import {LIGHTBOX_OPEN_EVENT_TOPIC} from '../App';
import {APP_GLOBAL} from "../App/DataManager";
import ContentBox from './../ContentBox';
import {
    Badge,
    BeyBroadcaster,
    BeyonityUiUtils,
    BreadcrumbNavigation,
    Button,
    ContentTile,
    Icon,
    PriceBadge,
    SequencialFadeIn,
    Share,
    ThumbnailButton,
    Toggle,
    Tooltip
} from '@beyonityeu/beyonity-ui-buttons';

import styles from './index.module.scss';
import {FormatData} from "../../utils";
import OptionsHeader from "../OptionsHeader/OptionsHeader";
import {CATEGORY_ALL, CATEGORY_COMMERCE, CATEGORY_LIVING, FILTERABLE_CATEGORIES} from "../FilterBox";
import BoxArea from "../BoxArea/BoxArea";




class DetailBox extends ContentBox {

    constructor(props) {
        super(props);

        this.state = {
            item: null,
            data: null,
            tooltipData: false,
            isOpen     : false,
            nextContent: null,
            displayedCategory: "all",
            isLandscape: window.innerHeight < window.innerWidth,
            ...this.state
        }
    }



    render() {
        super.render();
    }


    open(item) {
        super.open();
    }



    preview(item, filterSettings) {
        this.nextContent = item;
        this.nextFilterSettings = filterSettings;
        super.preview();
    }


    renderHeader() {
        super.renderHeader();
        const {item} = this.state;
        return (
            <div className={styles.headerTitle + " beyonity-ui--text__large-highlighted"}>
                {item && item.dsc}
            </div>
        )
    }


    onHidden() {
        this.setState({item  : this.nextContent,
            filterSettings   : this.nextFilterSettings,
            displayedCategory: this.nextFilterSettings.category
        });
    }



    renderBody = () => {
        super.renderBody();
        const {item, filterSettings} = this.state;
        const {language} = APP_GLOBAL;
        if (!item) return null;
        if (!filterSettings) return null;


        let media = [];
        if (item.properties.media) {
            media = item.properties.media.split(',');
        } else {
            media[0] = item.thumbnail;
        }

        // Check if the itemId is set in the URL and if so, override its value with this items id
        // IF there is no itemId set in the URL, add the parameter and its value to the URL
        let link = window.location.href;
        if (link.includes("itemID=")) {
            link = link.replace(/itemId=[0-9]+/, "itemId=" + item.id);
        } else {
            link = link + "&itemID=" + item.id;
        }

        // The badge Content

        let status = APP_GLOBAL.data.settings.status;

        let BadgeContent;
        let BadgeColor = status.not_available.color;


        if (filterSettings.category === CATEGORY_COMMERCE) {
            BadgeContent = item.units[filterSettings.category].freeArea;
            if (BadgeContent > 0) {
                BadgeColor = status.available.color;
            }
            BadgeContent = FormatData.formatArea(BadgeContent);
        }
        if (filterSettings.category === CATEGORY_LIVING) {
            BadgeContent = item.units[filterSettings.category].freeUnits;
            if (BadgeContent > 0) {
                BadgeColor = status.available.color;
            }
            BadgeContent = BadgeContent + " " + "Frei";
        }

        return (
            <>
                <div className={styles.subHeader}>
                    <div className="beyonity-ui--text__small color-text--gray-700">
                        {language?.categories[this.state.filterSettings.category].name}
                    </div>
                    {( item.location.address !== null && item.location.address !== {} ) &&
                        <div
                            className="beyonity-ui--text__medium-highlighted color-text--gray-800">{item.location.address}
                        </div>
                    }
                </div>
                    <OptionsHeader>
                        <div>
                            <PriceBadge color={BadgeColor}
                                        size={"m"}
                                        content={BadgeContent}/>
                        </div>
                        <div>
                            <Share
                                channels={['link', 'webshare', 'facebook', 'twitter', 'telegram', 'whatsapp', 'xing', 'linkedin']}
                                shareLink={link}
                                title={item && item.dsc}
                                parentSelector={"beyonity-ui-id--side-box"}
                                label={APP_GLOBAL.language?.share?.btn}
                                sharedContent={{
                                    dsc  : item && item.dsc,
                                    image: item && item.thumbnail,
                                    title: item && item.dsc,
                                    url  : link,
                                }}

                                langStrings={{
                                    mail: APP_GLOBAL.language?.share?.shareMail,
                                    more: APP_GLOBAL.language?.share?.shareMore,
                                    link: APP_GLOBAL.language?.share?.shareLink,
                                }}
                            />
                        </div>
                    </OptionsHeader>

                <ThumbnailButton thumbnail={item && item.thumbnail}
                                 label={APP_GLOBAL.language?.details?.media}
                                 iconLeft={"image"}
                                 iconRight={"arrow_right_alt"}
                                 onClick={() => {
                                     BeyBroadcaster.instance.publish(LIGHTBOX_OPEN_EVENT_TOPIC, {
                                         title         : item.dsc,
                                         src           : media,
                                         headerElements: []
                                     })
                                 }}/>
                {item && this.renderUsage(item)}
                {item && this.renderProperties(item)}
                <Tooltip displayTooltip={this.state.showToolTip}>
                    <ContentTile className={styles.tooltip}
                                 id={"tooltip"}
                                 elevation={"E200"}>
                        <div className={styles.tooltipContent}>
                            <div className={styles.label + ` beyonity-ui--text__small-highlighted`}>
                                {this.state.tooltipData.label}
                            </div>
                            <div className={styles.subLabel + ` beyonity-ui--text__small`}>
                                {this.state.tooltipData.subLabel}
                            </div>
                        </div>
                    </ContentTile>
                </Tooltip>
            </>
        )
    }

    renderFooter = () => {
        super.renderFooter();
        const {item, filterSettings} = this.state;

        return (
            <Button label={APP_GLOBAL.language?.details?.open?.navigator}
                    fullWidth
                    centered
                    size="l"
                    varian
                    buttonVariant={`main`}
                    buttonType={`primary`}
                    onClick={
                        () => {
                            console.log(item.webPath)
                            console.log(item.webPath[filterSettings.category])
                            console.log(BeyonityUiUtils.appendBacpArgumentsTo(item.webPath[filterSettings.category], {opener: "portfolio"}))
                            window.open(BeyonityUiUtils.appendBacpArgumentsTo(item.webPath[filterSettings.category], {opener: "portfolio"}), "_self");
                        }
                    }/>
        )
    }


    close(callback) {
        super.close(callback);
    }

    /**
     * This renders a tile with properties which each have a value, icon and a descriptions
     * @param item
     *  The object which contains the properties
     * @returns {JSX.Element}
     * The tile with the properties
     */
    renderProperties = (item) => {
        return (
            <BoxArea title={APP_GLOBAL.language?.details?.properties} className={styles.properties}>
                <div className={styles.propertyList}>
                    {
                        Object.entries(APP_GLOBAL.data.properties).map(([key, value]) => {
                            if (key === "media" || value.dsc === "" || item.properties[key] === "") return null;
                            return (
                                <div className={styles.property} key={"_" + key}>
                                    <Icon name={value.icon} size={"m"}
                                          color={"var(--color-text--gray-700)"}></Icon>
                                    <span className={"beyonity-ui--text__medium-highlighted color-text--gray-800"}>
                                               {item.properties[key]}
                                           </span>
                                    <span
                                        className={"beyonity-ui--text__small color-text--gray-700"}>{value.dscLong ? value.dscLong : value.dsc}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </BoxArea>
        )
    }


    renderUsage(item) {
        const {displayedCategory, onlyAvailable} = this.state;
        const functions = [];
        const {language} = APP_GLOBAL;
        item.availableCategories.forEach((category) => {
            if (!FILTERABLE_CATEGORIES.includes(category)) return;
            functions.push({
                id  : category,
                name: APP_GLOBAL.language.categories[category].name,
            });
        });

        functions.push({
            id  : "all",
            name: APP_GLOBAL.language.categories.all
        });


        let unitsCount;
        if (displayedCategory === CATEGORY_ALL) {
            unitsCount = onlyAvailable ? item.unitsFreeCount : item.unitsCount;
        } else {
            unitsCount = onlyAvailable ? item.units[displayedCategory].freeUnits : item.units[displayedCategory].totalUnits
        }

        return (
            <BoxArea title={APP_GLOBAL.language?.details?.usage} className={styles.usage}>
                <div className={styles.usageBody}>
                    <div className={styles.navigiagtion}>
                        <BreadcrumbNavigation size="s" navItems={functions}
                                              pointerPosition={displayedCategory}
                                              onNavChange={(item) => {
                                                  this.setState({displayedCategory: item});
                                              }}/>
                    </div>
                    <div className={styles.header}>
                        <div className={styles.left}>
                            <Toggle size={"xs"} onChange={(e) => {
                                this.setState({onlyAvailable: e})
                            }}/>
                            <span className={"beyonity-ui--text__small color-text--gray-800"}>nur verfügbar</span>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.title + " beyonity-ui--text__heading-1 color-text--gray-900"}>
                                {unitsCount}
                            </div>
                            <div
                                className={styles.name + " beyonity-ui--text__medium-highlighted color-text--gray-800"}>
                                {language.general.measurementUnits.units}
                            </div>
                        </div>
                    </div>
                    <div className={styles.categories}>
                        <SequencialFadeIn>
                            {
                                this._renderChart()
                            }
                        </SequencialFadeIn>
                    </div>
                </div>
            </BoxArea>
        );
    }


    _renderChart() {
        const {displayedCategory, item} = this.state;

        if (displayedCategory === CATEGORY_ALL) {
            return Object.keys(item.units).map((category) => {
                return Object.keys(item.units[category].types).map((typeName) => {
                    const {
                        free,
                        total,
                    } = item.units[category].types[typeName].area;

                    return this._renderChartBar(
                        free,
                        total,
                        (t) => t,
                        FormatData.formatArea,
                        category,
                        typeName,
                        item.areaTotal,
                        item.units[category].types[typeName]
                    );
                })
            })
        }
        if (displayedCategory === CATEGORY_LIVING) {
            return Object.keys(item.units[displayedCategory].rooms).map((roomType) => {
                const {
                    free,
                    total,
                } = item.units[displayedCategory].rooms[roomType].area;

                return this._renderChartBar(
                    free,
                    total,
                    FormatData.formatRooms,
                    FormatData.formatUnits,
                    displayedCategory,
                    roomType,
                    item.units[displayedCategory].totalUnits,
                    item.units[displayedCategory].rooms[roomType]
                );
            })
        }

        return Object.keys(item.units[displayedCategory].types).map((typeName) => {
            const {
                free,
                total,
            } = item.units[displayedCategory].types[typeName].area;
            return this._renderChartBar(
                free,
                total,
                (t) => t,
                FormatData.formatArea,
                displayedCategory,
                typeName,
                item.units[displayedCategory].totalArea,
                item.units[displayedCategory].types[typeName]
            );
        })
    }


    _renderChartBar = (free, total, formatTitle, formatSubTitle, category, type, categoryTotal, typeObject) => {

        let totalPercent = ( total / categoryTotal ) * 100;
        let freePercent = 100;
        let area = total;
        let badge = typeObject.numberOfUnits.total

        if (this.state.onlyAvailable) {
            freePercent = ( free / total ) * 100;
            area = free;
            badge = typeObject.numberOfUnits.free
        }

        return (
            <div className={styles.category} key={`${type}`}>
                <div className={styles.dataArea}>
                    <div className={styles.name + ` beyonity-ui--text__medium-highlighted color-text--gray-800`}>
                        {formatTitle(type)}
                    </div>
                    <div className={styles.name + ` beyonity-ui--text__small color-text--gray-700`}>
                        {formatSubTitle(area)}
                    </div>
                </div>
                <div className={styles.bar}>
                    <div className={styles.barFull} style={{width: `${totalPercent}%`}}>
                        <div className={styles.barFree} style={{width: `${freePercent}%`}}/>
                    </div>
                </div>
                <div className={styles.badge}>
                    <Badge size={"s"} className={styles.elevatedBadge}
                           backgroundColor={"var(--color-background--gray-0)"}>
                        <span
                            className={"beyonity-ui--text__medium-highlighted beyonity-ui--line-height__small color-text--gray-800"}>
                            {badge}
                        </span>
                    </Badge>
                </div>
            </div>
        )

    }
}


export default DetailBox;
