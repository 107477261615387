import "./PinProvider.css";




class PinProvider {

    commercePin = "<div class='beyonity-po--marker'>" +
        "<svg width=\"46\" height=\"51\" viewBox=\"0 0 46 51\" fill=\"none\">\n" +
        "        <g filter=\"url(#filter0_dd_15669_186110)\">\n" +
        "            <rect x=\"6.58667\" y=\"4.28711\" width=\"32\" height=\"32\" rx=\"16\" class=\"beyonity-po--marker__background\"/>\n" +
        "            <rect x=\"6.58667\" y=\"4.28711\" width=\"32\" height=\"32\" rx=\"16\" stroke=\"white\"  stroke-width=\"3\"/>\n" +
        "            <path\n" +
        "                d=\"M23.4527 41.7871C23.0678 42.4538 22.1055 42.4538 21.7206 41.7871L19.1226 37.2871C18.7377 36.6204 19.2188 35.7871 19.9886 35.7871L25.1847 35.7871C25.9545 35.7871 26.4357 36.6204 26.0508 37.2871L23.4527 41.7871Z\"\n" +
        "                fill=\"white\"/>\n" +
        "            <path d=\"M13.4199 28.5371V12.0371H22.5866V15.7038H31.7533V28.5371H13.4199ZM15.2533 26.7038H20.7533V24.8704H15.2533V26.7038ZM15.2533 23.0371H20.7533V21.2038H15.2533V23.0371ZM15.2533 19.3704H20.7533V17.5371H15.2533V19.3704ZM15.2533\n" +
        "                    15.7038H20.7533V13.8704H15.2533V15.7038ZM22.5866 26.7038H29.9199V17.5371H22.5866V26.7038ZM24.4199 21.2038V19.3704H28.0866V21.2038H24.4199ZM24.4199 24.8704V23.0371H28.0866V24.8704H24.4199Z\"\n" +
        "                 class=\"beyonity-po--marker__icon\"/>\n" +
        "        </g>\n" +
        "        <defs>\n" +
        "            <filter id=\"filter0_dd_15669_186110\" x=\"0.0866699\" y=\"0.787109\" width=\"45\" height=\"49.5\"\n" +
        "                    filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\">\n" +
        "                <feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/>\n" +
        "                <feColorMatrix in=\"SourceAlpha\" type=\"matrix\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\"\n" +
        "                               result=\"hardAlpha\"/>\n" +
        "                <feOffset/>\n" +
        "                <feGaussianBlur stdDeviation=\"0.5\"/>\n" +
        "                <feColorMatrix type=\"matrix\" values=\"0 0 0 0 0.258824 0 0 0 0 0.258824 0 0 0 0 0.258824 0 0 0 0.31 0\"/>\n" +
        "                <feBlend mode=\"normal\" in2=\"BackgroundImageFix\" result=\"effect1_dropShadow_15669_186110\"/>\n" +
        "                <feColorMatrix in=\"SourceAlpha\" type=\"matrix\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\"\n" +
        "                               result=\"hardAlpha\"/>\n" +
        "                <feOffset dy=\"3\"/>\n" +
        "                <feGaussianBlur stdDeviation=\"2.5\"/>\n" +
        "                <feColorMatrix type=\"matrix\" values=\"0 0 0 0 0.258824 0 0 0 0 0.258824 0 0 0 0 0.258824 0 0 0 0.15 0\"/>\n" +
        "                <feBlend mode=\"normal\" in2=\"effect1_dropShadow_15669_186110\" result=\"effect2_dropShadow_15669_186110\"/>\n" +
        "                <feBlend mode=\"normal\" in=\"SourceGraphic\" in2=\"effect2_dropShadow_15669_186110\" result=\"shape\"/>\n" +
        "            </filter>\n" +
        "        </defs>\n" +
        "    </svg>" +
        "</div>";


    livingPin = "\"<div class='beyonity-po--marker'>" +
        "<div class='beyonity-po--marker'>" +
        "<svg width=\"46\" height=\"51\" viewBox=\"0 0 46 51\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
        "<g filter=\"url(#filter0_dd_15669_186247)\">\n" +
        "<rect x=\"6.58667\" y=\"4.20312\" width=\"32\" height=\"32\" rx=\"16\" class=\"beyonity-po--marker__background\"/>\n" +
        "<rect x=\"6.58667\" y=\"4.20312\" width=\"32\" height=\"32\" rx=\"16\" stroke=\"white\" stroke-width=\"3\"/>\n" +
        "<path d=\"M23.4527 41.7031C23.0678 42.3698 22.1055 42.3698 21.7206 41.7031L19.1226 37.2031C18.7377 36.5365 19.2188 35.7031 19.9886 35.7031L25.1847 35.7031C25.9545 35.7031 26.4357 36.5365 26.0508 37.2031L23.4527 41.7031Z\" fill=\"white\"/>\n" +
        "<path d=\"M17.0868 26.6198H19.8368V21.1198H25.3368V26.6198H28.0868V18.3698L22.5868 14.2448L17.0868 18.3698V26.6198ZM15.2534 28.4531V17.4531L22.5868 11.9531L29.9201 17.4531V28.4531H23.5034V22.9531H21.6701V28.4531H15.2534Z\"  class=\"beyonity-po--marker__icon\"/>\n" +
        "</g>\n" +
        "<defs>\n" +
        "<filter id=\"filter0_dd_15669_186247\" x=\"0.0866699\" y=\"0.703125\" width=\"45\" height=\"49.5\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\">\n" +
        "<feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/>\n" +
        "<feColorMatrix in=\"SourceAlpha\" type=\"matrix\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\" result=\"hardAlpha\"/>\n" +
        "<feOffset/>\n" +
        "<feGaussianBlur stdDeviation=\"0.5\"/>\n" +
        "<feColorMatrix type=\"matrix\" values=\"0 0 0 0 0.258824 0 0 0 0 0.258824 0 0 0 0 0.258824 0 0 0 0.31 0\"/>\n" +
        "<feBlend mode=\"normal\" in2=\"BackgroundImageFix\" result=\"effect1_dropShadow_15669_186247\"/>\n" +
        "<feColorMatrix in=\"SourceAlpha\" type=\"matrix\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\" result=\"hardAlpha\"/>\n" +
        "<feOffset dy=\"3\"/>\n" +
        "<feGaussianBlur stdDeviation=\"2.5\"/>\n" +
        "<feColorMatrix type=\"matrix\" values=\"0 0 0 0 0.258824 0 0 0 0 0.258824 0 0 0 0 0.258824 0 0 0 0.15 0\"/>\n" +
        "<feBlend mode=\"normal\" in2=\"effect1_dropShadow_15669_186247\" result=\"effect2_dropShadow_15669_186247\"/>\n" +
        "<feBlend mode=\"normal\" in=\"SourceGraphic\" in2=\"effect2_dropShadow_15669_186247\" result=\"shape\"/>\n" +
        "</filter>\n" +
        "</defs>\n" +
        "</svg>\n"
        + "</div>"
        + "</div>"

    mixedPin = "<div class='beyonity-po--marker'>" +
        "<svg width=\"46\" height=\"50\" viewBox=\"0 0 46 50\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
        "<g filter=\"url(#filter0_dd_15669_186036)\">\n" +
        "<rect x=\"6.58667\" y=\"3.71875\" width=\"32\" height=\"32\" rx=\"16\" class=\"beyonity-po--marker__background\"/>\n" +
        "<rect x=\"6.58667\" y=\"3.71875\" width=\"32\" height=\"32\" rx=\"16\" stroke=\"white\" stroke-width=\"3\"/>\n" +
        "<path d=\"M23.4527 41.2187C23.0678 41.8854 22.1055 41.8854 21.7206 41.2188L19.1226 36.7188C18.7377 36.0521 19.2188 35.2188 19.9886 35.2188L25.1847 35.2188C25.9545 35.2188 26.4357 36.0521 26.0508 36.7188L23.4527 41.2187Z\" fill=\"white\"/>\n" +
        "<path d=\"M27.1701 16.9688H29.0034V15.1354H27.1701V16.9688ZM27.1701 20.6354H29.0034V18.8021H27.1701V20.6354ZM27.1701 24.3021H29.0034V22.4688H27.1701V24.3021ZM27.1701 27.9688V26.1354H30.8368V13.3021H22.5868V14.5854L20.7534 13.2563V11.4688H32.6701V27.9688H27.1701ZM12.5034 27.9688V18.8021L18.9201 14.2188L25.3368 18.8021V27.9688H19.8368V23.3854H18.0034V27.9688H12.5034ZM14.3368 26.1354H16.1701V21.5521H21.6701V26.1354H23.5034V19.7188L18.9201 16.4646L14.3368 19.7188V26.1354Z\" class=\"beyonity-po--marker__icon\"/>\n" +
        "</g>\n" +
        "<defs>\n" +
        "<filter id=\"filter0_dd_15669_186036\" x=\"0.0866699\" y=\"0.21875\" width=\"45\" height=\"49.5\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\">\n" +
        "<feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/>\n" +
        "<feColorMatrix in=\"SourceAlpha\" type=\"matrix\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\" result=\"hardAlpha\"/>\n" +
        "<feOffset/>\n" +
        "<feGaussianBlur stdDeviation=\"0.5\"/>\n" +
        "<feColorMatrix type=\"matrix\" values=\"0 0 0 0 0.258824 0 0 0 0 0.258824 0 0 0 0 0.258824 0 0 0 0.31 0\"/>\n" +
        "<feBlend mode=\"normal\" in2=\"BackgroundImageFix\" result=\"effect1_dropShadow_15669_186036\"/>\n" +
        "<feColorMatrix in=\"SourceAlpha\" type=\"matrix\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\" result=\"hardAlpha\"/>\n" +
        "<feOffset dy=\"3\"/>\n" +
        "<feGaussianBlur stdDeviation=\"2.5\"/>\n" +
        "<feColorMatrix type=\"matrix\" values=\"0 0 0 0 0.258824 0 0 0 0 0.258824 0 0 0 0 0.258824 0 0 0 0.15 0\"/>\n" +
        "<feBlend mode=\"normal\" in2=\"effect1_dropShadow_15669_186036\" result=\"effect2_dropShadow_15669_186036\"/>\n" +
        "<feBlend mode=\"normal\" in=\"SourceGraphic\" in2=\"effect2_dropShadow_15669_186036\" result=\"shape\"/>\n" +
        "</filter>\n" +
        "</defs>\n" +
        "</svg>\n" +
        "</div>"



    getPinFor(marker) {
        if (marker.category.includes("living") && marker.category.includes("commerce")) {
            return this.mixedPin;
        }
        if (marker.category.includes("living")) {
            return this.livingPin;
        }
        if (marker.category.includes("commerce")) {
            return this.commercePin;
        }
    }


    getActiveClassNameFor(marker) {
        return "beyonity-po--pin__active";
    }

}

export default PinProvider;