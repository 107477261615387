export const request = async (url = '', data = {}, method = 'GET') => {
    method = method.toUpperCase();
    const opt = {
        method     : method,
        cache      : 'no-cache',
        mode       : 'cors',
        credentials: 'same-origin',
        referrerPolicy: 'no-referrer',
    };
    if (method !== 'GET') {
        opt.body = data;
    } else {
        opt.headers = {'Content-Type': 'application/json'};
        const base = !/^https?:\/\//.test(url) ? window.document.baseURI : undefined;
        url = new URL(url, base);
        url.search = new URLSearchParams(data).toString();
    }
    const response = await fetch(url, opt);
    return response.json();
};

export const FormatData = {
    init       : (country = 'de-CH',
                  currency = 'CHF',
                  areaUnit = 'm²',
                  roomsUnit = 'Zimmer',
                  unitsUnit = 'Einheit'
    ) => {
        FormatData.locale.country = country.split('-')[1].toLowerCase();
        FormatData.locale.currency = currency;
        FormatData.locale.areaUnit = areaUnit;
        FormatData.locale.roomsUnit = roomsUnit;
        FormatData.locale.unitsUnit = unitsUnit;
    },
    locale     : {
        country: '',
        currency: '',
        areaUnit: '',
        roomsUnit: '',
        unitsUnit: ''
    },
    formatNumber: (number = 0, digits = 0) => {
        const thsnd = /\B(?=(\d{3})+(?!\d))/g;
        number = parseFloat(number).toFixed(digits);
        switch (FormatData.locale.country) {
            case 'it':
            case 'fr':
            case 'at':
            case 'de':
                return number.replace(/\./, ',').replace(thsnd, '.');
            case 'ch':
                return number.replace(thsnd, "'");
            default://en
                return number.replace(thsnd, ",");
        }
    },
    formatPrice: (price = '') => {
        switch (FormatData.locale.country) {
            case 'it':
            case 'fr':
            case 'at':
            case 'de':
                return price + ' ' + FormatData.locale.currency;
            case 'ch':
            default:
                return FormatData.locale.currency + ' ' + price;
        }
    },
    formatArea : (area = '') => {
        switch (FormatData.locale.country) {
            case 'it':
            case 'fr':
            case 'at':
            case 'de':
                return `${FormatData.formatNumber(area)} ${FormatData.locale.areaUnit}`;
            case 'ch':
            default:
                return `${FormatData.formatNumber(area)} ${FormatData.locale.areaUnit}`;
        }
    },
    formatRooms: (numberOfRooms = '') => {
        switch (FormatData.locale.country) {
            case 'it':
            case 'fr':
            case 'at':
            case 'de':
            case 'ch':
            default:
                return `${numberOfRooms} ${FormatData.locale.roomsUnit}`;
        }
    },
    formatUnits: (numberOfRooms = '') => {
        switch (FormatData.locale.country) {
            case 'it':
            case 'fr':
            case 'at':
            case 'de':
            case 'ch':
            default:
                return `${numberOfRooms} ${FormatData.locale.unitsUnit}`;
        }
    }
};

export const replaceMarkedStringWith = (str = '', replacements = [], callback = () => null, pattern = '##@...##') => {
    pattern = pattern.split('...');
    let idx = -1;
    return str.split(pattern[1]).map(v => {
        if (( new RegExp('^' + pattern[0].replace(pattern[1], '')) ).test(v)) {
            if (idx < replacements.length) {
                idx++;
            }
            return callback(idx, v.substring(1));
        } else {
            return v;
        }
    });
};
