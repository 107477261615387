import React, {Component} from 'react';
import {APP_GLOBAL} from "../App/DataManager";
import {BeyBroadcaster, BeyonityUiUtils} from '@beyonityeu/beyonity-ui-buttons';

import styles from './index.module.scss';
import PropTypes from "prop-types";




export const SIDEBAR_TOGGLE_TOPIC = 'sidebar-toggle';

class Sidebar extends Component {

    transitionDuration = 250;
    rto;


    constructor(props) {
        super(props);
        this.state = {
            hidden: false
        };
        APP_GLOBAL.components.sidebar = {};
    }


    componentDidMount() {

        BeyBroadcaster.instance.subscribe(SIDEBAR_TOGGLE_TOPIC, (cb = () => {
        }) => {
            this.setState({hidden: !this.state.hidden});
        });
    }


    componentWillUnmount() {
        window.clearTimeout(this.rto);
    }


    render() {
        const {hidden} = this.state,
            {className, children} = this.props;

        return (
            <div
                id="sidebar"
                className={BeyonityUiUtils.generateClassNames([styles.sidebar, className, hidden && styles.hidden])}
                style={{transition: 'margin ' + this.transitionDuration + 'ms ease-in-out'}}
            >
                {children}
            </div>
        );
    }

}

Sidebar.defaultProps = {
    className: ''
};

Sidebar.propTypes = {
    className: PropTypes.string,
    /**
     * Is expects two divs (left side and right side) filled with buttons
     * they will be displayed in the toolbar
     */
    tools: PropTypes.arrayOf(PropTypes.element),
};

export default Sidebar;
