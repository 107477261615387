import React, {Component} from 'react';
import {BeyonityUiUtils} from '@beyonityeu/beyonity-ui-buttons';

import styles from './index.module.scss';




class PlayerControls extends Component {

    topLeft;
    topRight;
    bottomLeft;
    bottomRight;
    topTop;


    constructor(props) {
        super(props);
    }


    set(...args) {
        args.forEach(p => this[p.pos] = p.content);
        this.forceUpdate();
    }


    reset(...args) {
        args.forEach(p => this[p] = null);
        this.forceUpdate();
    }


    render() {
        const {topLeft, topRight, bottomLeft, bottomRight, topTop, className} = this.props

        return (
            <div className={BeyonityUiUtils.generateClassNames([styles.controls, className])}>
                <div className={styles.top}>
                    <div className={styles.topTop}>
                        {this.topTop || topTop}
                    </div>
                    <div className={styles.topBottom}>
                        <div className={styles.left}>{this.topLeft || topLeft}</div>
                        <div className={styles.right}>{this.topRight || topRight}</div>
                    </div>

                </div>
                <div className={styles.bottom}>
                    <div className={styles.left}>{this.bottomLeft || bottomLeft}</div>
                    <div className={styles.right}>{this.bottomRight || bottomRight}</div>
                </div>
            </div>
        );
    }

}

export default PlayerControls;
