import React, {Component} from 'react';
import {BeyonityUiUtils} from '@beyonityeu/beyonity-ui-buttons';

import styles from './index.module.scss';




class ContentBoxBody extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        const {children, className} = this.props;

        return (
            <div
                className={BeyonityUiUtils.generateClassNames([styles.body, 'scrollbar', 'scrollbar--light'])}>
                {children}
            </div>
        );
    }

}

export default ContentBoxBody;
